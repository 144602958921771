import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import * as Style from './Common/StyledComponents';
import APICall from '../Common/APICall.js';
import WelcomeBanner from './WelcomeBanner'
import SideButtonItems from './SideButtonItems';
import PersonalInfo from './PersonalInfo';
import GeneralInfo from './GeneralInfo';
import Emails from './Emails';
import ProfilePic from './Profilepic';
import SecurityInfo from './SecurityInfo';
import MessageModal from './MessageModal';
import ApiKeySecret from './ApiKeySecret';
import CSLLoader from './Common/CSLLoader';

const SaveButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 107px;
    font-size: 13px;
    height: 35px;
    border-radius: 3px;
    margin-right:10px;
    cursor: pointer;
`;

const CancelButton = styled.button`
    box-sizing: border-box;
    background-color: #ffffff;
    color: #90949e;
    border: 1px solid #ced1d6;
    width: 75px;
    font-size: 13px;
    height: 35px;
    border-radius: 3px;
    margin-right:10px;
    cursor: pointer;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -80px;
  z-index: 1000;
  opacity: 0.8;
`;

class SelectIndex extends React.Component
{
	state = {  view_count: 0, 
               cur_view: 'profile',     
		       screen:{profile:1,password:0,preferences:0},
               contact_name :'',
               profile_picture:'',
               email_address:'',
               direct:'',
               known_as:'',
               is_email_valid : 1,
               isContactNameProvided : 0,
               isEmailProvided : 0,
               current_password : '',
               new_password : '',
               confirm_password : '',
               is_password_match : 1,
               modal_view :null,
               reset_password_msg:'',
               isPrimaryContact : null,
               contact_id: 0,
               user_data: null,
               modules: null,
               languages: [], 
               user_langs: {},
               is_lan: false 

			}

	constructor(props) {
		super(props);
	}
	componentDidMount = () => {
        let postData = { command: "index" };
        let api = new APICall();
        api.command(postData, this.processIndexData);
    }
    processIndexData = (result) => { 
        console.log("result===>", result)
        var isContactNameProvided = result.contactInfo.ContactName == '' ? 0 : 1;
        var isEmailProvided = result.contactInfo.EmailAddress == '' ? 0 : 1;
        let modules = JSON.parse(JSON.stringify(result.user_data.modules));
        modules.forEach((module, index) => {
          module.selected = index === 0 ? true : false;
        })
        let langs = result.languages['result']; 
        this.setState({
                ready: true, contact_name:result.contactInfo.ContactName, profile_picture: result.contactInfo.ProfilePicture, 
                email_address:result.contactInfo.EmailAddress, direct: result.contactInfo.Direct, known_as:result.contactInfo.KnownAs,
                isContactNameProvided : isContactNameProvided, isEmailProvided : isEmailProvided, isPrimaryContact :result.contactInfo.isPrimaryContact,
                user_data: result.user_data, modules,contact_id: result.contact_id, languages: langs, user_langs: result.user_langs, is_lan: result.is_lan
            });
    }
    onFilesAdded = (files) => { 
        console.log("files123", files) 
        let postData = { command: "fileupload", bin_file: files[0].bin_file};
        let api = new APICall();
        api.command(postData, this.processImageData);
    }
    processImageData = (result) => {
        console.log("processImageData",result)
        this.setState({profile_picture: result.result});
    }
    processData = (result) => {
        if(result.error_code == 0){
            alert(result.error_msg);
        }else{
            this.setState({modal_view:'open_successful_modal', reset_password_msg:result.error_msg})
        }
        
        this.setState({isContactNameProvided : 1, isEmailProvided : 1, current_password: '',new_password:'',confirm_password:''})
    }
	refreshState = (cur_view, key, val, is_email_valid, is_password_match) => {
        this.setState({cur_view : cur_view, [key] :val,is_email_valid:is_email_valid, is_password_match : is_password_match});
    }

    handleChange =(event) =>{
        event.preventDefault();
    }
    handleSave = (event)=>{
    	event.preventDefault();
        if(this.state.cur_view == 'profile'){
            var isContactNameProvided = this.state.contact_name == '' ? 0 : 1;
            var isEmailProvided = this.state.email_address == '' ? 0 : 1;
            if(isContactNameProvided == 1 && isEmailProvided == 1 && this.state.is_email_valid == 1){
                let postData = { command: "updateContacts", contact_info: this.state};
                let api = new APICall();
                api.command(postData, this.processData);
            }else{
                this.setState({isContactNameProvided : isContactNameProvided, isEmailProvided : isEmailProvided})
            }
        }
        else if(this.state.cur_view == 'password'){
            if(this.state.is_password_match ==1){
                let postData = { command: "updateLoginPassword", contact_info: this.state};
                let api = new APICall();
                api.command(postData, this.processData);
            }
        }
    }

    selectAppLanding = (modified_user_data) => {
        this.setState({user_data: modified_user_data});
    }

    saveUserLang = (lang) => {
        let postData = { command: "save_user_lang", selct_lang: lang};
        let api = new APICall();
        api.command(postData, this.afterSavelang);
    }

    afterSavelang = (result) => {
        // console.log("result ===>", result)
        this.setState({user_langs: result})
        alert("Language Preferances saved successfully");
    }

    saveUserData = (lang, is_lan) => {
        let postData = { command: "save_user_data", user_data: this.state.user_data};
        postData['lang'] = lang;
        postData['is_lan'] = is_lan;
        let api = new APICall();
        api.command(postData, this.afterSave);
    }

    afterSave = () => {
        alert("Configuration saved successfully");
    }

    toogleModule = (modules) => {
      this.setState({modules: modules});
    }

    manipulateModules = (modules) => {
      // console.log("modules", modules);
      this.setState({modules: modules});
    }

    saveEmails = () => {
      let modules = JSON.parse(JSON.stringify(this.state.modules));
      modules.forEach((m) => {
        delete m.selected;
      })
      // console.log(modules);
      let user_data = JSON.parse(JSON.stringify(this.state.user_data));
      user_data.modules = modules;
      // console.log(user_data);
      let postData = { command: "save_user_data", user_data: user_data};
      let api = new APICall();
      api.command(postData, this.afterSave);
    }


   
	render()
	{
        // console.log("index component", this.state.isPrimaryContact);
		if(!this.state.ready) {
            return <div>Loading...</div>
        }
		return (

            <div>
                <WelcomeBanner welcome={this.state.contact_name}/> 
                <div style={{marginTop: "40px", width : "100%", boxSizing: "border-box"}}>
                    <SideButtonItems refreshParrentCallback = {this.refreshState} modules={this.state.modules} toogleModule={this.toogleModule}/> 
                    {
                         (() => {
                             switch(this.state.cur_view) {                            
                                case 'profile' : return  <PersonalInfo refreshParentCallback={this.refreshState} saveUserLang={this.saveUserLang} contact_info = {this.state}/> ; break;
                                case 'password' : return <SecurityInfo refreshParentCallback={this.refreshState} contact_info = {this.state}/>;  break;
                                case 'preferences' : return <SecurityInfo refreshParentCallback={this.refreshState} />; break;
                                case 'general' : return <GeneralInfo user_data={this.state.user_data} selectAppLanding={this.selectAppLanding} saveUserData={this.saveUserData} languages={this.state.languages} user_langs={this.state.user_langs} is_lan={this.state.is_lan} contact_id={this.state.contact_id} />; break;
                                case 'emails' : return <Emails modules={this.state.modules} manipulateModules={this.manipulateModules} saveEmails={this.saveEmails} />; break;
                                default: return <Style.NoPermission>Sorry, you do not have permission to view this page. </Style.NoPermission>;
                             }
                             
                         })()
                     }
                     {
                        (() => {
                            if(this.state.cur_view == 'profile'){
                                return <ProfilePic  ProfilePicture = {this.state.profile_picture} onFilesAdded = {this.onFilesAdded}/>;
                            }
                        })()
                     }
                     {
                        (() => {
                            if(this.state.cur_view == 'password' && this.state.isPrimaryContact){
                                return <ApiKeySecret emailAddress = {this.state.email_address}/>;
                            }
                        })()
                     }
                     <div style={{clear: "both"}}></div>
                </div>
                {
                    (() => {
                        if(this.state.modal_view !== null) {
                             return <div style={{position:'absolute', left:'10%', top:'50px', width:'100%', height:'1186px'}}><InactiveOverlay /><MessageModal/></div>;
                        }
                    })()
                }
                {
                    (() => {
                        if (this.state.cur_view === "profile" || this.state.cur_view === "password") {
                            return (
                                <div style={{float:"right", marginTop:"20px", marginLeft: "565px", position:"absolute"}}>
                                     <CancelButton>Cancel</CancelButton>                           
                                     <SaveButton onClick = {this.handleSave}>Save</SaveButton>
                                </div>
                            );
                        }
                    })()
                }
                <CSLLoader />
            </div>
		  	
		);
	}
}

export default SelectIndex;