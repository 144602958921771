import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import CSLCheckBox from './Common/CSLCheckBox';

const SaveButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 107px;
    font-size: 13px;
    height: 35px;
    border-radius: 3px;
    cursor: pointer;
`;
const CancelButton = styled.button`
    box-sizing: border-box;
    background-color: #ffffff;
    color: #90949e;
    border: 1px solid #ced1d6;
    width: 75px;
    font-size: 13px;
    height: 35px;
    border-radius: 3px;
    margin-right:10px;
    cursor: pointer;
`;

class Emails extends React.Component
{
	state = {single_module: null};

	componentDidMount() {
		let single_module = this.props.modules.find(m => m.selected === true);
		this.setState({single_module: JSON.parse(JSON.stringify(single_module))});
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			let single_module = this.props.modules.find(m => m.selected === true);
			this.setState({single_module: JSON.parse(JSON.stringify(single_module))});
		}
	}

	toogleCheckBox = (single_mail) => {
		// console.log("single_mail", single_mail);
		let modules = JSON.parse(JSON.stringify(this.props.modules));
		modules.forEach((module) => {
			if (module.selected === true) {
				// console.log("module", module);
				module.emails.forEach((mail) => {
					if (mail.uid === single_mail.uid) {
						mail.active = single_mail.active;
						// console.log("mail", mail);
					}
				})
			}
		})
		console.log("check modules", modules);
		this.props.manipulateModules(modules);
	}

	saveEmails = (event) => {
		event.preventDefault();
		this.props.saveEmails();
	}

	render()
	{
		return (
			<div style={{float: "left", marginLeft:"2px", width: "55%"}}>
				<div style={{fontWeight: "600", padding: "19px", backgroundColor:"#ffffff"}}>
					<div style={{float: "left"}}>Email Notifications</div>
					<div style={{float: "right", color: "#AEAFAF"}}>Email</div>
					<div style={{clear: "both"}}></div>
				</div>
				<div style={{width: "100%", height: "1px", backgroundColor: "#dcd7d7"}}></div>
				<div style={{padding: "30px 19px", boxSizing: "border-box", backgroundColor:"#ffffff"}}>
					{
						(() => {
							if (this.state.single_module !== null) {
								return (
									<div>
										{
											this.state.single_module.emails.map((mail, index) => {
												if (mail.company_wide_active === true) {
													return (
														<div key={mail.uid} style={{paddingTop: index === 0 ? "0px" : "20px"}}>
															<div style={{float: "left", fontWeight: "bold"}}>
																{mail.name}
															</div>
															<div style={{float: "right"}}>
																<CSLCheckBox mail={mail} toogleCheckBox={this.toogleCheckBox} />
															</div>
															<div style={{clear: "both"}}></div>
														</div>
													);
												}
											})
										}
									</div>
								);
							}
						})()
					}
				</div>
				<div style={{float: "right", marginTop: "20px"}}>
					<CancelButton>Cancel</CancelButton>                           
                    <SaveButton onClick={this.saveEmails}>Save</SaveButton>
				</div>
				<div style={{clear: "right"}}></div>
			</div>
		);
	}
}

export default Emails;