import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import * as Style from './Common/StyledComponents';
import APICall from '../Common/APICall.js';
import WelcomeBanner from './WelcomeBanner'
import SideAuditlogMenuItems from './SideAuditlogMenuItems'
import ActivityLog from './ActivityLog.js'
import EmailLog from './EmailLog.js'
import DataChangeLog from './DataChangeLog.js'
import CSLLoader from './Common/CSLLoader';
import {FaArrowLeft} from "react-icons/all";

const ErrorBar = styled.div`
    background-color: #F6DBDB;
    color: #DE8D8E;
    font-weight: 600;
    padding: 10px 20px;
`;

const moduleUrls = {
    "attest": process.env.REACT_APP_ATTEST_URL,
    "breach": process.env.REACT_APP_BREACH_URL,
    "cmp": process.env.REACT_APP_CMP_URL,
    "conflicts": process.env.REACT_APP_CONFLICTS_URL,
    "finpromo": process.env.REACT_APP_FINPROMO_URL,
    "gconfig": process.env.REACT_APP_GCONFIG_URL,
    "gifts": process.env.REACT_APP_GIFTS_URL,
    "committees": process.env.REACT_APP_GOVERNANCE_URL,
    "padreg": process.env.REACT_APP_PADREG_URL,
    "teams": process.env.REACT_APP_TEAMS_URL,
    "rcm": process.env.REACT_APP_RCM_URL,
    "tmview": process.env.REACT_APP_TMVIEW_URL,
    "filechecker": process.env.REACT_APP_FILECHECKER_URL,
    "car": process.env.REACT_APP_CAR_URL,
    "docmgmt": process.env.REACT_APP_DOCMGMT_URL,
}

class AuditlogsIndex extends React.Component
{
	state = {
               ready: false,
               view_count: 0,
               cur_view: 'activitylog',
		           screen:{changelog : 0, activitylog : 1, emaillog : 0},
               module:{},
               contact_name :'',
               menu_obj : '',
               activitylog_data :{},
               contact_list : {},
               gc_companies : {},
               client_name : '',
               emaillog : {},
               data_change_log: {},
               staff_list: {},
               module_id: 'general',
               role: ''
			}

	componentDidMount = () => {
    let module_id = this.props.match ? this.props.match.params.moduleID : 'general';
    console.log("componentDidMount_Auditlogs ====>", module_id)
        let postData = { command: "logindex", module: module_id };
        let api = new APICall();
        api.command(postData, this.processData);
        this.setState({module_id: module_id})
    }

    processData = (result) => {
        console.log("Auditlogs data ====>",result)
        this.setState({
                ready: true,
                contact_name :result.loggedin_contact.contact_name,
                contact_list : result.contact_list,
                gc_companies : result.gc_companies,
                client_name : result.clientname,
                staff_list : result.staff_list,
                role: result.role
            });
        // Store.updateStore({data_change_log: this.state.data_change_log});
    }
    refreshCallBack = (cur_view) => {
        this.setState({cur_view : cur_view})
    }

    closePage = (event) => {
        event.preventDefault();
        let url = moduleUrls[this.state.module_id];
        if (!url) {
            console.log("No URL found for module", this.state.module_id);
        }
        window.location.replace(url);
    }

    backToModule = () => {
        let url = moduleUrls[this.state.module_id];
        if (!url) {
            console.log("No URL found for module", this.state.module_id);
        }

        window.location.replace(url)
    }

	render()
	{
    let module_id = this.props.match ? this.props.match.params.moduleID : 'general';
        console.log("state ===>", this.state)


    if(!this.state.ready) {
            return <CSLLoader />
        }
    if (this.state.role !== 'admin_manager' && this.state.role !== 'manager') {
        return (
            <ErrorBar>
                <div style={{float: "left"}}>You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
                <div style={{float: "right", fontSize: "16px", cursor: "pointer"}} onClick={this.closePage}>x</div>
                <div style={{clear: "both"}}></div>
            </ErrorBar>
        );
    }
		return (

            <div>
                <a onClick={this.backToModule} style={{cursor: 'pointer'}}><FaArrowLeft style={{fontSize: 20, marginLeft: 10, marginTop: 10}}/></a>
                <WelcomeBanner welcome={this.state.contact_name}/>
                <div style={{marginTop: "40px", width : "100%", position: "relative"}}>
                    <SideAuditlogMenuItems refreshParrentCallback ={ this.refreshCallBack} />
                    {
                        (() =>{
                            switch(this.state.cur_view) {
                                case 'activitylog' : return <ActivityLog activitylog_data = {this.state.activitylog_data} contact_list = {this.state.contact_list} gc_companies = {this.state.gc_companies} client_name = {this.state.client_name} module_id = {this.state.module_id}/>; break;
                                case 'emaillog' : return <EmailLog emaillog_data = {this.state.emaillog} module_id = {module_id}/>
                                case 'changelog' : return <DataChangeLog contact_list = {this.state.contact_list} gc_companies = {this.state.gc_companies} client_name = {this.state.client_name} module_id = {module_id} staff_list = {this.state.staff_list} />
                                default: return <Style.NoPermission>Sorry, you do not have permission to view this page. </Style.NoPermission>;
                            }
                        })()
                    }
                </div>
            </div>

		);
	}
}

export default AuditlogsIndex;
