import React from 'react';
import styled from 'styled-components';
import Store from '../../Common/Store';
import APICall from '../../Common/APICall';
import {FaAngleRight} from 'react-icons/fa';
import CSLTable from '../../Common/CSLTable'
import EmailModal_CSL from '../EmailModal_CSL'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 65px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -56px;
  z-index: 1000;
  opacity: 0.8;
`;
const Dropdownlist = styled.select`
   height: 20px;
   border-radius: 4px;
   width: 150px;
   margin-left: 30px;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
`;


class EmailLog_CSL extends React.Component
{
        state = { 
            ready: false,
            emaillog_data : {},
            client_name : '',
            modal_view : null,
            modal_data : {},
            ddlmodule_val : 0,
            ddldate_val : 'anydate',
            startDate: new Date(),
            endDate: new Date()
    }

    componentDidMount = () => {
    console.log("componentDidMount_EmailLog")
    console.log("Store.getStoreData('is_admin')",Store.getStoreData('is_admin'));

        let postData = { command: "getauditlog_CSL" }
        let api = new APICall();
        api.command(postData, this.processData);      
    
    }

    processData = (result) => {
    console.log("State====>",this.state)
    console.log("Result3====>",result)
    this.setState({
        ready: true,        
        emaillog_data : result.emaillog,
        gc_companies : result.gc_companies,
    });
    }

    calculateDateInTimestamp = function (inputDate) {
            var date_input = new Date(parseInt(inputDate));
            var date_output = date_input.getDate() + '/' + (date_input.getMonth() + 1) + '/' + date_input.getFullYear()
            return date_output;
    }
    getGroupCompanies =(company_id)=>{
            var group_company_id = parseInt(company_id) % 100000;
            let group_company_name ='';
            let gc_companies = this.props.gc_companies;
            let grp ={};    
            if(gc_companies.length > 0){
                for(var k = 0 ; k<gc_companies.length; k++){          
                grp[gc_companies[k].id] = gc_companies[k].company_name;
                }
            }
            group_company_name = grp[group_company_id]
            return group_company_name;
    }
    openEmailModal = (id, module_id, subject, sent_date, recipient_name, recipient_email) => (event) => {
            event.preventDefault();
            var email_data = {};
            for(let i= 0; i< this.state.emaillog_data.length ; i++){
            email_data[this.state.emaillog_data[i].id] = this.state.emaillog_data[i];
            }
            var modal_data = {module_name : module_id , subject : subject, sent_date : sent_date, recipient_name : recipient_name, recipient_email : recipient_email, email_data : email_data[id]}
            this.setState({modal_view : 'emailmodal', modal_data : modal_data})     
    }

    processAcceptanceQueueData = () => {

            let lane_end = '';
            let emaillogData = this.state.emaillog_data;
            console.log("Result_email", emaillogData);
            let ret = {data: [], columns: []};
            let i = 1;
            ret.columns =[{Header: 'Module', accessor: 'module_id', minWidth: 75, headerStyle: {textAlign: 'left'}},
                        {Header: 'Subject', accessor: 'subject', minWidth: 230, headerStyle: {textAlign: 'left'}},      
                        {Header: 'Recipient Name', accessor: 'recipient_name', minWidth: 95, headerStyle: {textAlign: 'left'}},
                        {Header: 'Recipient Email', accessor: 'recipient_email', minWidth: 170, headerStyle: {textAlign: 'left'}},
                        {Header: 'Status', accessor: 'status', minWidth: 65, headerStyle: {textAlign: 'left', visible:'false'}},
                        {Header: 'Resent', accessor: 'resent', minWidth: 46, headerStyle: {textAlign: 'left', visible:'false'}},
                        {Header: 'Sent Date', accessor: 'sent_date', minWidth: 65, headerStyle: {textAlign: 'left', visible:'false'}},
                        {'Header' : 'Action', Cell: row => (
                            <div>
                                <MRButton onClick={this.openEmailModal(row.original.id, row.original.module_id, row.original.subject, row.original.sent_date, row.original.recipient_name, row.original.recipient_email)}>View</MRButton>
                            </div>
                        ), width: 75, headerStyle: {textAlign: 'center'}},
                        ];
                        for(let k = 0 ; k< emaillogData.length ; k++) 
                        {  
                            let emailjson = JSON.parse(emaillogData[k].email_json);
                            let sent_date = new Date(emailjson.send_date);
                            let compare_date = this.addMonths(new Date(), -parseInt(this.state.ddldate_val));
                            let start_date = this.state.startDate;
                            let endDate = this.state.endDate
                            let sent_date_int = sent_date.getFullYear() * 10000 + (sent_date.getMonth() +1) * 100 + sent_date.getDate();
                            let compare_date_int = compare_date.getFullYear() * 10000 + (compare_date.getMonth() +1) *100 + compare_date.getDate();
                            if(this.state.ddldate_val == 10000){
                            let start_date_int = start_date.getFullYear() * 10000 + (start_date.getMonth() +1) * 100 + start_date.getDate();
                            let end_date_int = endDate.getFullYear() * 10000 + (endDate.getMonth() +1) * 100 + endDate.getDate();
                            
                            if(sent_date_int>= start_date_int && sent_date_int <= end_date_int){
                                    let elem = {
                                        // 'index': k,
                                                'module_id': emailjson.module,
                                                'subject' : emailjson.subject,
                                                'recipient_name' : emailjson.recieipent_name,
                                                'recipient_email' : emailjson.recieipent_email,
                                                'status' : emailjson.status,
                                                'resent' : '00',
                                                'sent_date' : this.calculateDateInTimestamp(emailjson.send_date),
                                                'id' : emaillogData[k].id
                                            };
                                ret.data.push(elem);
                            }
                            }else if(sent_date_int >= compare_date_int){
                                let elem = {
                                    // 'index': k,
                                            'module_id': emailjson.module,
                                            'subject' : emailjson.subject,
                                            'recipient_name' : emailjson.recieipent_name,
                                            'recipient_email' : emailjson.recieipent_email,
                                            'status' : emailjson.status,
                                            'resent' : '00',
                                            'sent_date' : this.calculateDateInTimestamp(emailjson.send_date),
                                            'id' : emaillogData[k].id
                                        };
                            ret.data.push(elem);
                            }
                            
                        }   
            Store.updateStore('table_cur_data', ret.data)
            return ret;      
    }
    refreshState = (view) =>{
            this.setState({modal_view : view})
    }
    getDaysInMonth = (year, month) =>{
            return new Date(year, month, 0).getDate();
    }

    addMonths = (input, months) => {   
        months = isNaN(months) ? parseInt(-100) : months;
        const date = new Date(input)
        date.setDate(1)
        date.setMonth(date.getMonth() + months);
        date.setDate(Math.min(input.getDate(), this.getDaysInMonth(date.getFullYear(), date.getMonth()+1)));
        return date;
    }
    handleEndChange = (date) => {
        if(date < this.state.startDate){
        alert('End date must be greater than start date');
        return;
        }
        this.setState({
        endDate: date
        });
    }
    changeDateFilter = (event)=>{
        event.preventDefault();
        this.setState({ddldate_val: event.target.value});
    }
    handleStartChange = (date) => {
        if(date > this.state.endDate){
        alert('Start date must be smaller than end date');
        return;
        }
        this.setState({
        startDate: date
    });
    }
    exportToCSV  = (event)=>{
        event.preventDefault();
        var cur_table_data = Store.getStoreData('table_cur_data');
        var cur_table_arr = [];
        for(let i = 0; i<  cur_table_data.length; i++){
        var container = [];
        container = Object.values(cur_table_data[i])
        if(container.lenght > 0); container.shift(); container.pop() 
        cur_table_arr.push(container)
        }
        let csvContent = "data:text/csv;charset=utf-8," 
        + cur_table_arr.map(e => e.join("  ")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "Email_Log.csv");
        document.body.appendChild(link); 
        link.click();
    }
    render()
    {    
        if(!this.state.ready) {
        return <div>Loading...</div>
        }
        return (
            <div style={{display:"inline-block" , width:"100%", height:"900px" ,backgroundColor:"#ffffff"}}>
                <div style={{position:"absolute", padding: "17px",width: "97%" }}> 
                    {
                        (() => {
                            if(this.state.ddldate_val == 10000) {
                                return ( <div style={{position: "absolute", marginLeft:"100px", marginTop:"1px", zIndex: "100"}}>
                                <div style={{display:"inline-block"}}>
                                            <label style={{paddingLeft:"10px", color: "#9e9e9e", fontWeight: "600", paddingRight:"3px"}}>Start Date : </label>
                                            <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.startDate} onChange={this.handleStartChange}/>
                                </div>
                                <div style={{display:"inline-block"}}>
                                            <label style={{paddingLeft:"10px", color: "#9e9e9e", fontWeight: "600", paddingRight:"3px"}}>End Date : </label>
                                            <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.endDate} onChange={this.handleEndChange}/>
                                </div>
                                            

                                            
                                        </div>);
                            }
                        })()
                    }
                    <div style={{float:"right"}}>
                    
                    <Dropdownlist id = "ddlmodule" onChange={this.changeModuleFilter} value={this.state.ddlmodule_val}>
                            <option value="0">All</option>
                            <option value="filechecker">File Checker</option>
                            <option value="rcm">RCM</option>
                            <option value="car">CAR</option>
                            <option value="cmp">CMP</option>
                        </Dropdownlist> 

                        <Dropdownlist id = "ddldate" onChange={this.changeDateFilter} value={this.state.ddldate_val}>
                            <option value="1000">Any date</option>
                            <option value="1">Last Month</option>
                            <option value="6">Last Six Months</option>
                            <option value="12">Last Year</option>
                            <option value="10000">Custom</option>
                        </Dropdownlist>     
                    </div>
                                                
                    <CSLTable add={false} processData={this.processAcceptanceQueueData} headerText={'Email Log'} tableRows={20}/> 
                    <ExportButton onClick={this.exportToCSV}>Export</ExportButton>    
                </div>
                {
                    (() => {
                        if(this.state.modal_view !== null) {
                            return <div style={{position:'absolute', left:'10%', top:'50px', width:'100%', height:'1186px'}}><InactiveOverlay /><EmailModal_CSL refreshParent={this.refreshState} modalData = {this.state.modal_data}/></div>;
                        }
                    })()
                }
            </div>         		  	 
    );
    }
}

export default EmailLog_CSL;