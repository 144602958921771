import React from 'react';
import styled from 'styled-components';
// import CslTypeAhead from './../Common/CslTypeAhead';
import Store from './../../Common/Store';
import APICall from '../../Common/APICall.js';
import { Multiselect } from "multiselect-react-dropdown";
import AlertBox from '../Common/AlertBox';

const ConfigBtn = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    font-size: 13px;
    border-radius: 4px;
    padding: 5px 20px;
    cursor: pointer;
`;
const GeneralContainer = styled.div`
    width: 90%;
`;
const GeneralInnerContainer = styled.div`
    background-color: #ffffff;
    padding: 20px;
`;
const GeneralLabel = styled.div`
    font-weight: 600;
    color: #595959;
    margin-top: 20px;
`;
const FooterButtonsContainer = styled.div`
    text-align: right;
    padding-top: 15px;
    padding-bottom: 12px;
`;
const SaveBtn = styled.button`
    padding: 10px 30px;
    background-color: #37ADA7;
    border: 1px solid #37ADA7;
    border-radius: 2px;
    color: #ffffff;
    cursor: pointer;
    margin-left: 15px;
`;

class JwgFeeds extends React.Component
{
    state = { 
        ready: false,
        company_arr: [],
        isclear: false,
        selected_companies: [],
        id:"0",
        company_jwg_arr: [],
        final_selection: [],
        alert_param: null
        
    }; 

    constructor(props) {
        super(props);
    } 

    componentDidMount() {
        this.setState({ready: true})
        this.callCompany()
        this.getJwg()
    }
        
    // componentDidUpdate(prevProps) {
    //     if (prevProps !== this.props) {
    //         this.setState({ready: true})
    //         this.callCompany()
    //     }
    // }
    
    callCompany = () => {
        let api = new APICall();
        let postData = { "command": "get_company_no_filter" };
        api.command(postData, this.process);
    }
    
     process = (result) => {
        this.setState({company_arr:result})   
        // console.log("get_company_no_filter",this.state)
    }
    
    getJwg = () => {
        let postData = { command: "get_jwg_data" };
        // postData["company_arr"] = this.state.company_jwg_arr
        let api = new APICall();
        api.command(postData, this.processJwgData)
    }
    
    processJwgData = (result) => {
        if(result) {
            //this.setState({company_jwg_arr: result.company_arr, id: result._id})
            this.setState({selected_companies: result.company_arr, id: result._id})
        }
    }

    onSelect = (selectedList,selectedItems) => {
        let selected_companies_arr = this.state.selected_companies
        let selected = selectedItems
        selected_companies_arr.push(selected)
        this.setState({
            ...this.state,selected_companies: selected_companies_arr
        })
    }
    
    onRemove = (selectedList,removedItems) => {
        let selected_companies_arr = this.state.selected_companies;
        //console.log('selected_companies_arr ==>',selected_companies_arr)
        let removed_company = removedItems;
        let companies = selected_companies_arr.filter(item => item.id != removed_company.id);
        
        this.setState({
          ...this.state, selected_companies: companies
        })
        
    }
    
    jwg_save = () => {
            let final_companies = this.state.selected_companies;
            let id = this.state.id;
            console.log("Selected Company ",final_companies)
            let postData = {command: "save_jwg_data",final_companies,id};
            const api = new APICall();
            api.command(postData, this.processJWG);

    }
     processJWG = (result) => {
        if (result.error_code === 0) {
            let alert_param = { title: 'Alert', message: 'Client List have been updated', ok_text: 'OK', confirm: false, alertHandler: this.alertHandler, stack: result }
            this.setState({ alert_param: alert_param });
        } else {
            let alert_param = { title: 'Error', message: result.error_msg, ok_text: 'OK', confirm: false, alertHandler: this.alertHandler, stack: { id: 0 } }
            this.setState({ alert_param: alert_param });
        }
    }
    
    alertHandler = (result, stack) => {
        this.setState({ alert_param: null})
    }
 
    render() {
        //console.log("state======>",this.state)
        //console.log('selected_companies =>',this.state.selected_companies);
        if (this.state.ready === false) {
            return <div />;
        }
      return (
        <GeneralContainer>
            <GeneralInnerContainer>
                <GeneralLabel>JWG feeder</GeneralLabel>
                <Multiselect
                  options={this.state.company_arr}
                  onSelect={this.onSelect}
                  onRemove={this.onRemove}
                  displayValue="CompanyName"
                  showCheckbox={true}
                  //selectedValues={this.state.company_jwg_arr}
                  selectedValues={this.state.selected_companies}
                />
                {/*<CslTypeAhead 
                    data={this.state.company_arr} 
                    ricipientValue={this.ricipientValue} 
                    isclear={this.state.isclear} 
                    changeClear={this.changeClear}
                />*/}
            </GeneralInnerContainer>    
            <FooterButtonsContainer>
                <SaveBtn onClick={() => this.jwg_save()}>Save</SaveBtn>
            </FooterButtonsContainer>
            {
                (() => {
                    if (this.state.alert_param !== null) {
                        return <AlertBox alertParam={this.state.alert_param} />
                    }
                })()
            }
        </GeneralContainer>
      )
    }
}

export default JwgFeeds;