import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import * as Style from './Common/StyledComponents';
import {FaAngleRight} from 'react-icons/fa';

const SideButton_UnSelected = styled.div`
    background-color: #ffffff;
    width: 100%;
    color: #000000;
    padding: 13px 7px 13px 15px;
    margin-bottom: 4px;
    :hover {
        color: #000000;
        cursor: pointer;
        font-weight: bold;
    }
`;
const SideButton_Selected = styled.div`
    background-color: #ffffff;
    width: 100%;
    color: #000000;
    padding: 13px 7px 13px 15px;
    font-weight: bold;
    margin-bottom: 4px;
    :hover {
        color: #000000;
        cursor: pointer;
        font-weight: bold;
    }
`;
var menu_by_permission = {}
class MenuButtonItems extends React.Component
{
	state = {   ready: false,
				cur_view: null,
		        screen:{filechecker:0, car:0, rcm:0, dashboard:0, taskmanager:0},
                menu_by_permission : {}
			}

	constructor(props) {
		super(props);
	}

    componentDidMount = () => {
        var menu_arr = this.props.menuObj;
        menu_by_permission = {};
        for(let i= 1; i< menu_arr.length; i++){
            menu_by_permission[menu_arr[i].MenuCaption.replace(/ /g, '').toLowerCase()] = 1;
        }
        this.setState({ready: true, menu_by_permission: menu_by_permission})
    }
    handleButton =(event) =>{
        event.preventDefault();
        var screen = this.state.screen;
        var current_view = null;
        screen.filechecker = 0;
        screen.car = 0;
        screen.rcm = 0;
        screen.dashboard = 0;
        screen.taskmanager = 0;
        if([event.target.id] == 'filechecker'){
            screen.filechecker = 1;
            current_view = 'filechecker';
        }
        if([event.target.id] == 'car'){
            screen.car = 1;
            current_view = 'car';
        }
        if([event.target.id] == 'rcm'){
            screen.rcm = 1;
            current_view = 'rcm';
        }
        if([event.target.id] == 'dashboard'){
            screen.dashboard = 1;
            current_view = 'dashboard';
        }
        if([event.target.id] == 'taskmanager'){
            screen.taskmanager = 1;
            current_view = 'taskmanager';
        }
        this.props.refreshParrentCallback(current_view);

        if(current_view == 'filechecker'){
           window.location.href = "/moduleconfig/mccgeneral_notifications";
        }else if(current_view == 'rcm'){
            window.location.href = "/moduleconfig/mccgeneral_notifications";
        }else if(current_view == 'car'){
            window.location.href = "/moduleconfig/mccgeneral_notifications";
        }

        this.setState({screen : screen})
    }

	render()
	{
       console.log("menu_by_permission",this.state.menu_by_permission)
		return (
            <div style={{display:"inline-block" , width:"20%", height:"400px"}}>
               <div style={{position:"absolute"}}>
                <div style={{padding: "0px 15px 15px 10px"}}>
                    {
                        (() =>{
                            if('filechecker' in this.state.menu_by_permission){
                                if(this.state.screen.filechecker === 1){
                                return <SideButton_Selected id = "filechecker" onClick={this.handleButton} >File Checker <FaAngleRight style = {{color:"8ED7D5", float:"right", paddingTop: "3px"}}/> </SideButton_Selected>
                              }else{
                                return <SideButton_UnSelected id = "filechecker" onClick={this.handleButton} >File Checker <FaAngleRight style = {{color:"8ED7D5", float:"right", paddingTop: "3px"}}/> </SideButton_UnSelected>
                              }
                            }

                        })()

                    }
                    {
                        (() =>{
                            if('complianceadviceregister' in this.state.menu_by_permission){
                                if(this.state.screen.car === 1){
                                return <SideButton_Selected id = "car" onClick={this.handleButton} >Compliance Advice Register<FaAngleRight style = {{color:"8ED7D5", float:"right", paddingTop: "3px"}}/> </SideButton_Selected>
                              }else{
                                return <SideButton_UnSelected id = "car" onClick={this.handleButton} >Compliance Advice Register<FaAngleRight style = {{color:"8ED7D5", float:"right", paddingTop: "3px"}}/> </SideButton_UnSelected>
                              }
                            }

                        })()

                    }
                    {
                        (() =>{
                            if('regulatorychangemanagement' in this.state.menu_by_permission){
                                if(this.state.screen.rcm === 1){
                                return <SideButton_Selected id = "rcm" onClick={this.handleButton} >Regulatory Change Management <FaAngleRight style = {{color:"8ED7D5", float:"right", paddingTop: "3px"}}/> </SideButton_Selected>
                              }else{
                                return <SideButton_UnSelected id = "rcm" onClick={this.handleButton} >Regulatory Change Management <FaAngleRight style = {{color:"8ED7D5", float:"right", paddingTop: "3px"}}/> </SideButton_UnSelected>
                              }
                            }

                        })()

                    }
                    {
                        (() =>{
                            if('dashboard' in this.state.menu_by_permission){
                                if(this.state.screen.dashboard === 1){
                                return <SideButton_Selected id = "dashboard" onClick={this.handleButton} >Dashboard <FaAngleRight style = {{color:"8ED7D5", float:"right", paddingTop: "3px"}}/> </SideButton_Selected>
                              }else{
                                return <SideButton_UnSelected id = "dashboard" onClick={this.handleButton} >Dashboard <FaAngleRight style = {{color:"8ED7D5", float:"right", paddingTop: "3px"}}/> </SideButton_UnSelected>
                              }
                            }

                        })()

                    }
                    {
                        (() =>{
                             if('taskmanager' in this.state.menu_by_permission){
                                if(this.state.screen.taskmanager === 1){
                                return <SideButton_Selected id = "taskmanager" onClick={this.handleButton} >Task Manager <FaAngleRight style = {{color:"8ED7D5", float:"right", paddingTop: "3px"}}/> </SideButton_Selected>
                              }else{
                                return <SideButton_UnSelected id = "taskmanager" onClick={this.handleButton} >Task Manager <FaAngleRight style = {{color:"8ED7D5", float:"right", paddingTop: "3px"}}/> </SideButton_UnSelected>
                              }
                             }

                        })()

                    }
                </div>
               </div>
            </div>


        );
	}
}

export default MenuButtonItems;
