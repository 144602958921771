import Store from './Store.js';
import http from 'mcc-front-aux/dist/httpCommon'
import { trackPromise } from 'react-promise-tracker';

class APICall {
  constructor() {
    this.url = Store.getStoreData('api_url');
    this.is_admin = Store.getStoreData('is_admin');
    this.is_debug = Store.getStoreData('is_debug');
    this.module_name = Store.getStoreData('module_name');

  }

  async command(postData, callback) {
    postData.env = "select";

    let jsonDataString = JSON.stringify(postData).replace(/&/g, '--and--');

    console.log("httpVerb",this.url)

    // Add in the json command if it exists
    let postURL = this.url
    if('command' in postData) {
      postURL += `/${postData.command}`
    }


    trackPromise(http.post(postURL, {jsondata: jsonDataString}).then(res => {
      res = res.data.body
      res = JSON.parse(res);
      callback(res);
    }).catch(error => {
        console.log(error);
        return(null)
      }))

    // trackPromise(fetch(this.url, httpVerb)
    //   .then(res => res.json())
    //   .then(result => {
    //     callback(result);
    //   }).catch(error => {
    //     console.log(error);
    //     return(null)
    //   }));
  }
}

export default APICall;
