import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import * as Style from './Common/StyledComponents';
import {FaAngleRight, FaAngleUp, FaAngleDown} from 'react-icons/fa';

const SideButton_UnSelected = styled.div`
    background-color: #ffffff;
    width: 100%;
    color: #000000;
    padding: 13px 7px 13px 15px;
    margin-bottom: 4px;
    box-sizing: border-box;
    :hover {
        color: #000000;
        cursor: pointer;
        font-weight: bold;
    }
`;
const SideButton_Selected = styled.div`
    background-color: #EAEFF6;
    width: 100%;
    color: #000000;
    padding: 13px 7px 13px 15px;
    font-weight: bold;
    margin-bottom: 4px;
    box-sizing: border-box;
    :hover {
        color: #000000;
        cursor: pointer;
        font-weight: bold;
    }
`;
const SubMenuUnselected = styled.div`
    background-color: #ffffff;
    width: 100%;
    color: #000000;
    padding: 13px 5px 13px 25px;
    box-sizing: border-box;
    cursor: pointer;
`;
const SubMenuSelected = styled.div`
    background-color: #c1c0c0;
    font-weight: bold;
    width: 100%;
    color: #000000;
    padding: 13px 5px 13px 25px;
    box-sizing: border-box;
    cursor: pointer;
`;

class SideButtonItems extends React.Component
{
	state = { 
				cur_view: null, 			
				cur_view: null,
                modules: null,
		        screen:{profile:1,password:0}
			}

	constructor(props) {
		super(props);
	}

    componentDidMount() {
        this.setState({modules: this.props.modules});
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({modules: this.props.modules});
        }
    }

    handleButton =(event) =>{
        event.preventDefault();
        var screen = this.state.screen;
        var current_view = null;
        // screen.profile = 0;
        // screen.password = 0;
        // screen.preferences = 0;
        // screen.general = 0;
        // screen.emails = 0;
        if([event.target.id] == 'profile'){
            screen.profile = 1;
            screen.password = 0;
            current_view = 'profile';
            this.props.refreshParrentCallback(current_view) 
        }
        if([event.target.id] == 'password'){
            screen.password = 1;
            screen.profile = 0;
            current_view = 'password';
            this.props.refreshParrentCallback(current_view) 
        }
        // if([event.target.id] == 'preferences'){
        //     screen.preferences = 1;
        //     current_view = 'preferences'
        // }
        // if([event.target.id] == 'general'){
        //     screen.general = 1;
        //     current_view = 'general'
        // }
        // if([event.target.id] == 'emails'){
        //     screen.emails = 1;
        //     current_view = 'emails'
        // } 
        this.setState({screen : screen}) 
    }

    toogleModule = (module_name) => (event) => {
        event.preventDefault();
        let modules = JSON.parse(JSON.stringify(this.state.modules));
        modules.forEach((module) => {
            module.selected = module.module === module_name ? true : false;
        })
        this.props.toogleModule(modules);
    }

	render()
	{
        console.log("modules:", this.state.modules);
		return (
            <div style={{padding: "0px 15px 15px 10px", float: "left", boxSizing: "border-box", display: "inlineBlock"}}>
                {
                    (() =>{
                          if(this.state.screen.profile === 1){
                            return <SideButton_Selected id = "profile" onClick={this.handleButton} >Profile <FaAngleRight style={{color:"8ED7D5", float:"right", paddingTop: "3px", paddingLeft: "10px"}}/><div style={{clear: "both"}}></div> </SideButton_Selected>
                          }else{
                            return <SideButton_UnSelected id = "profile" onClick={this.handleButton} >Profile <FaAngleRight style={{color:"8ED7D5", float:"right", paddingTop: "3px", paddingLeft: "10px"}}/><div style={{clear: "both"}}></div> </SideButton_UnSelected>
                          }
                    })()
                }
                {
                    (() =>{
                          if(this.state.screen.password === 1){
                            return <SideButton_Selected id = "password" onClick={this.handleButton} >Password / Security <FaAngleRight style={{color:"8ED7D5", float:"right", paddingTop: "3px", paddingLeft: "10px"}}/><div style={{clear: "both"}}></div> </SideButton_Selected>
                          }else{
                            return <SideButton_UnSelected id = "password" onClick={this.handleButton} >Password / Security <FaAngleRight style={{color:"8ED7D5", float:"right", paddingTop: "3px", paddingLeft: "10px"}}/><div style={{clear: "both"}}></div> </SideButton_UnSelected>
                          }
                    })()
                }
{/*                {
                    (() =>{
                          if(this.state.screen.general === 1){
                            return <SideButton_Selected id = "general" onClick={this.handleButton} >General <FaAngleRight style={{color:"8ED7D5", float:"right", paddingTop: "3px", paddingLeft: "10px"}}/><div style={{clear: "both"}}></div> </SideButton_Selected>
                          }else{
                            return <SideButton_UnSelected id = "general" onClick={this.handleButton} >General <FaAngleRight style={{color:"8ED7D5", float:"right", paddingTop: "3px", paddingLeft: "10px"}}/><div style={{clear: "both"}}></div> </SideButton_UnSelected>
                          }
                    })()
                }
                {
                    (() =>{
                          if(this.state.screen.emails === 1){
                            return (
                                <div>
                                    <SideButton_Selected style={{marginBottom: "0px"}} id = "emails" onClick={this.handleButton} >
                                        Email Notifications <FaAngleUp style={{color:"8ED7D5", float:"right", paddingTop: "3px", paddingLeft: "10px"}}/>
                                        <div style={{clear: "both"}}></div>
                                    </SideButton_Selected>
                                    {
                                        this.state.modules.map((module) => {
                                            if (module.emails.length !== 0) {
                                                let show = false;
                                                module.emails.forEach((em) => {
                                                    if (em.company_wide_active === true) {
                                                        show = true;
                                                    }
                                                })
                                                if (show === true) {
                                                    if (module.selected === true) {
                                                        return <SubMenuSelected key={module.CSLMenuID}>{module.nickname}</SubMenuSelected>
                                                    } else {
                                                        return <SubMenuUnselected key={module.CSLMenuID} onClick={this.toogleModule(module.module)}>{module.nickname}</SubMenuUnselected>
                                                    }
                                                }
                                            }
                                        })
                                    }
                                </div>)
                          }else{
                            return <SideButton_UnSelected id = "emails" onClick={this.handleButton} >Email Notifications <FaAngleDown style={{color:"8ED7D5", float:"right", paddingTop: "3px", paddingLeft: "10px"}}/><div style={{clear: "both"}}></div> </SideButton_UnSelected>
                          }
                    })()
                }*/}
            </div>
		);
	}
}

export default SideButtonItems;