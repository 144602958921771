import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import * as Style from './Common/StyledComponents';
import APICall from '../Common/APICall.js';
import WelcomeBanner from './WelcomeBanner'
import MenuButtonItems from './MenuButtonItems';
import PersonalInfo from './PersonalInfo'
import ProfilePic from './Profilepic'
import SecurityInfo from './SecurityInfo'

const SaveButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 107px;
    font-size: 13px;
    height: 35px;
    border-radius: 3px;
    margin-right:10px;
`;

const CancelButton = styled.button`
    box-sizing: border-box;
    background-color: #ffffff;
    color: #90949e;
    border: 1px solid #ced1d6;
    width: 75px;
    font-size: 13px;
    height: 35px;
    border-radius: 3px;
    margin-right:10px;
`;

class NotificationIndex extends React.Component
{
	state = {  ready : false,
               view_count: 0, 
               cur_view: 'filechecker',     
		       screen:{},
               contact_name :'',
               menu_obj : ''
			}

	constructor(props) {
		super(props);
	}
	componentDidMount = () => {
        let postData = { command: "getmenubyclient" };
        let api = new APICall();
        api.command(postData, this.processData);
    }
    processData = (result) => {
        this.setState({
                ready: true , menu_obj :result.menu_obj.recordset, contact_name :result.contactName.result.result.recordset[0].ContactName
            });
    }
    
	refreshState = (cur_view, key, val, is_email_valid, is_password_match) => {
    }

    handleChange =(event) =>{
        event.preventDefault();
    }

	render()
	{
		if(!this.state.ready) {
            return <div>Loading...</div>
        }
		return (
            <div>
                <WelcomeBanner welcome={this.state.contact_name}/> 
                <div style={{marginTop: "40px", width : "100%", position: "relative"}}>
                    <MenuButtonItems refreshParrentCallback = {this.refreshState} menuObj = {this.state.menu_obj}/> 
                </div>
            </div>
		  	
		);
	}
}

export default NotificationIndex;