import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import * as Style from './Common/StyledComponents';
import {FaAngleRight} from 'react-icons/fa';
import APICall from '../Common/APICall.js';
import {FaTimes} from 'react-icons/fa';


const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    padding: 20px;
    width: 54%;
    left: 4%;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #004273;
    font-weight: bold;
    font-size: 24px;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalBodyInner = styled.div`
    border : 1px solid;
    border-color: #C2C2C2
    border-radius: 4px;
    padding: 15px;
    background-color: #ffffff;
`;
const MRModalLabel = styled.label`
    color: #a0a0a0;
    font-weight: 300;
    margin-top: 10px;
    padding-left: 6px;
`;
const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const MRModalDivider = styled.div`
    background-color: #A0A0A0;
    height:3px;
    margin-top: 20px;
`;
const Spacer = styled.div`
  height: 2px;
`;
const MRModalLabelTitle = styled.label`
    color: #a0a0a0;
    font-weight: bold;
    margin-top: 10px;
`;
const LoginButton = styled.button`
    box-sizing: border-box;
    background-color: #86B039;
    color: #FFFFFF;
    border: 1px solid #86B039;
    width: 100px;
    font-size: 13px;
    height: 26px;
    border-radius: 2px;
    margin-top: 72px;
    margin-bottom: 10px;
    float: right;
`;

class ActivityCompletedModal extends React.Component
{
	state = {
				modalData : ''
			}

	componentDidMount()
    {
        this.setState({summaryData : this.props.modalData})
    }

    closeModal = (event)=>{
        event.preventDefault();
        this.props.refreshParent(null)
    }
    redirectToLogin = (event)=>{
        event.preventDefault();
        window.location.href = "/" ;
    }
	render()
	{

        return (
            <div>
                <MRModalContainer>
                    <MRModalHeader>
                        <MRModalHeaderText>Resest Password</MRModalHeaderText>

                        <Spacer/>
                        <Spacer/>
                        <Spacer/>
                        <MRModalHeaderText style={{fontSize:"17px", color:"#000000", marginTop:"30px"}}>Your password has been successfully reset.</MRModalHeaderText>
                        <Spacer/>
                        <Spacer/>
                        <Spacer/>
                        <LoginButton onClick={this.redirectToLogin}>Log In</LoginButton>
                    </MRModalHeader>

                </MRModalContainer>
            </div>
		);
	}
}

export default ActivityCompletedModal;
