import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import moment from 'moment';
import * as Style from './Common/StyledComponents';
import {FaAngleRight} from 'react-icons/fa';
import CSLTable from './Common/CSLTable.js'
import APICall from '../Common/APICall';
import EmailModal from './EmailModal'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CSLLoader from './Common/CSLLoader';
import AdvancedFilter from './Partials/AdvancedFilter';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -80px;
  z-index: 1000;
  opacity: 0.8;
`;
const Dropdownlist = styled.select`
   height: 20px;
   border-radius: 4px;
   width: 150px;
   margin-left: 30px;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
`;
const Source_Map = {filechecker: 'File Checker', car: 'Compliance Advice Register', rcm: 'Regulatory Change Management', cmp: 'Compliance Monitoring Programme',conflicts: 'Conflicts Register', tmview: 'Generic', gifts: 'Gifts', attest: 'Policy Attestation', breach: 'Incident Register',financialcrimes: "Financial Crimes Register", docmgmt: "Document Management"}
const Activity_Shown = {COMP_FCK_CREATE: 1, COMP_FCK_COMPLETE: 1, COMP_FCK_QC_ASSIGNMENT : 1, COMP_FCK_QC_COMPLETE: 1};
const Activity_Text = {'COMP_FCK_CREATE': 'Filecheck Assigned', 'COMP_FCK_COMPLETE': 'Filecheck Completed', 'COMP_FCK_QC_ASSIGNMENT' : 'Filecheck Quality Assurance Assigned', 'COMP_FCK_QC_COMPLETE': 'Filecheck Quality Assurance Completed'};
class EmailLog extends React.Component
{
	state = { 
                emaillog_data : {},
                client_name : '',
                modal_view : null,
                modal_data : {},
                ddlmodule_val : 0,
                ddldate_val : 'anydate',
                startDate: new Date(),
                endDate: new Date(),
                module_id: 'general',
                header_title: "General Email Log",
                is_loaded: false,
                prefered_columns: null,
                view_count: 0,
                apply_filter: null,
                overloaded: false,
                errorloaded: false
	}
    
  componentDidMount = () => {
        // this.setState({emaillog_data : this.props.emaillog_data, module_id : this.props.module_id})    
         this.fetchEmailLog(this.state.apply_filter, 0);  
  }
  componentDidUpdate(prevProps)
  {
    if (prevProps !== this.props) {
      // this.setState({emaillog_data : this.props.emaillog_data, module_id : this.props.module_id}) 
       this.fetchEmailLog(this.state.apply_filter, 0);   
    }
  }


  fetchEmailLog = (filter, clear) => {
      let module_id = this.props.module_id;
      let postData = {};
      let api = new APICall();
      if(Store.getStoreData('emaillog_data') === null || filter !== null || clear === 1) {
        postData['command'] = 'getemaillog';
        postData['module'] = module_id;
        postData['filter'] = filter;
        api.command(postData, this.processData)
      }
      let prefered_columns ={};
      prefered_columns['module_id'] = 'Module';
      prefered_columns['subject'] = 'Subject';
      prefered_columns['recipient_name'] = 'Recipient Name';
      prefered_columns['recipient_email'] = 'Recipient Email';
      prefered_columns['status'] = 'Status';
      prefered_columns['resent'] = 'Re-Sent';
      prefered_columns['sent_date'] = 'Sent Date';
      this.setState({module_id: this.props.module_id, prefered_columns: prefered_columns})
  }

  processData = (result) => {
    let overloaded = this.state.overloaded;
    let errorloaded = this.state.errorloaded;
    if(result.error_code === 0){
      Store.updateStore('emaillog_data', result.result);
      overloaded = false;
      errorloaded = false;
    }else if(result.error_code === 11111){
      overloaded = true;
      errorloaded = false;
    }else{
      errorloaded = true;
      overloaded = false;
    }
    
    let m = this.state.m;
    this.setState({view_count : this.state.view_count + 1, m:m, overloaded: overloaded, errorloaded: errorloaded});
  }
  
  calculateDateInTimestamp = function (inputDate) {
    console.log("inputDate", inputDate)
        var date_input = new Date(parseInt(inputDate));
        var date_output = ('0' + date_input.getDate()).slice(-2) + '/' + ('0' + (date_input.getMonth()+1)).slice(-2) + '/' + date_input.getFullYear()
        return date_output;
  }
  getGroupCompanies =(company_id)=>{
        var group_company_id = parseInt(company_id) % 100000;
        let group_company_name ='';
        let gc_companies = this.props.gc_companies;
        let grp ={};    
        if(gc_companies.length > 0){
            for(var k = 0 ; k<gc_companies.length; k++){          
              grp[gc_companies[k].id] = gc_companies[k].company_name;
            }
        }
        group_company_name = grp[group_company_id]
        return group_company_name;
  }
  openEmailModal = (id, module_id, subject, sent_date, recipient_name, recipient_email) => (event) => {
        event.preventDefault();
        var email_data = {};
        let emaillogData = Store.getStoreData('emaillog_data') === null ? [] : Store.getStoreData('emaillog_data');
        for(let i= 0; i< emaillogData.length ; i++){
          email_data[emaillogData[i].id] = emaillogData[i];
        }
        console.log("email_data", email_data)
        var modal_data = {module_name : module_id , subject : subject, sent_date : sent_date, recipient_name : recipient_name, recipient_email : recipient_email, email_data : email_data[id]}
        this.setState({modal_view : 'emailmodal', modal_data : modal_data})     
  }

  processAcceptanceQueueData = () => {
        
        let lane_end = '';
        let emaillogData = Store.getStoreData('emaillog_data') === null ? [] : Store.getStoreData('emaillog_data');
        // console.log("Result_email", emaillogData);
        let ret = {data: [], columns: []};
        let i = 1;
        ret.columns =[{Header: 'Module', accessor: 'module_id', minWidth: 170, headerStyle: {textAlign: 'left'}},
                      {Header: 'Subject', accessor: 'subject', minWidth: 270, headerStyle: {textAlign: 'left'}},      
                      {Header: 'Recipient Name', accessor: 'recipient_name', minWidth: 170, headerStyle: {textAlign: 'left'}},
                      {Header: 'Recipient Email', accessor: 'recipient_email', minWidth: 230, headerStyle: {textAlign: 'left'}},
                      {Header: 'Status', accessor: 'status', minWidth: 120, headerStyle: {textAlign: 'left', visible:'false'}},
                      {Header: 'Re-sent', accessor: 'resent', minWidth: 120, headerStyle: {textAlign: 'left', visible:'false'}},
                      {Header: 'Sent Date', accessor: 'sent_date', minWidth: 120, headerStyle: {textAlign: 'left', visible:'false'}},
                      {'Header' : 'Action', Cell: row => (
                        <div>
                              <MRButton onClick={this.openEmailModal(row.original.id, row.original.module_id, row.original.subject, row.original.sent_date, row.original.recipient_name, row.original.recipient_email)}>View</MRButton>
                        </div>
                      ), width: 120, headerStyle: {textAlign: 'center'}},
                    ];
                     /*for(let k = 0 ; k< emaillogData.length ; k++) 
                     {  
                        // console.log("emil log ====>", JSON.parse(emaillogData[k].email_json), emaillogData[k].id);
                        let emailjson = emaillogData[k].email_json;
                        emailjson = JSON.parse(emailjson);
                        // console.log("emailjson.module", Source_Map[emailjson.module]);
                        let sent_date = new Date(emailjson.send_date);
                        let compare_date = this.addMonths(new Date(), -parseInt(this.state.ddldate_val));
                        let start_date = this.state.startDate;
                        let endDate = this.state.endDate
                        let sent_date_int = sent_date.getFullYear() * 10000 + (sent_date.getMonth() +1) * 100 + sent_date.getDate();
                        let compare_date_int = compare_date.getFullYear() * 10000 + (compare_date.getMonth() +1) *100 + compare_date.getDate();
                        if(this.state.ddldate_val == 10000){
                          let start_date_int = start_date.getFullYear() * 10000 + (start_date.getMonth() +1) * 100 + start_date.getDate();
                          let end_date_int = endDate.getFullYear() * 10000 + (endDate.getMonth() +1) * 100 + endDate.getDate();
                          
                          if(sent_date_int>= start_date_int && sent_date_int <= end_date_int){
                                let elem = {'index': k,
                                            'module_id':  typeof Source_Map[emailjson.module] === "undefined" ? emailjson.module : Source_Map[emailjson.module]  ,
                                            'subject' : emailjson.subject,
                                            'recipient_name' : ('recieipent_name' in emailjson) ? emailjson.recieipent_name : '',
                                            'recipient_email' : ('recieipent_email' in emailjson) ? emailjson.recieipent_email : '',
                                            'status' : emailjson.status === "Delivered" ? "Sent" : emailjson.status,
                                            'resent' : '00',
                                            'sent_date' : this.calculateDateInTimestamp(emailjson.send_date),
                                            'id' : emaillogData[k].id
                                           };
                              ret.data.push(elem);
                          }
                        }else if(sent_date_int >= compare_date_int){
                            let elem = {'index': k,
                                        'module_id':  typeof Source_Map[emailjson.module] === "undefined" ? emailjson.module : Source_Map[emailjson.module],
                                        'subject' : emailjson.subject,
                                        'recipient_name' : ('recieipent_name' in emailjson) ? emailjson.recieipent_name : '',
                                        'recipient_email' : ('recieipent_email' in emailjson) ? emailjson.recieipent_email : '',
                                        'status' : emailjson.status === "Delivered" ? "Sent" : emailjson.status,
                                        'resent' : '00',
                                        'sent_date' : this.calculateDateInTimestamp(emailjson.send_date),
                                        'id' : emaillogData[k].id
                                       };
                          ret.data.push(elem);
                        }
                        
                     } */  
                     for(let k = 0 ; k< emaillogData.length ; k++) {
                        let emailjson = JSON.parse(emaillogData[k].email_json);
                        let elem = {'index': k,
                                    'module_id':  typeof Source_Map[emailjson.module] === "undefined" ? emailjson.module : Source_Map[emailjson.module]  ,
                                    'subject' : emailjson.subject,
                                    'recipient_name' : ('recieipent_name' in emailjson) ? emailjson.recieipent_name : '',
                                    'recipient_email' : ('recieipent_email' in emailjson) ? emailjson.recieipent_email : '',
                                    'status' : emailjson.status === "Delivered" ? "Sent" : emailjson.status,
                                    'resent' : '00',
                                    'sent_date' : this.calculateDateInTimestamp(emailjson.send_date),
                                    'id' : emaillogData[k].id
                                   };
                      ret.data.push(elem);
                     }
        Store.updateStore('table_cur_data', ret.data)
        return ret;      
  }
  refreshState = (view) =>{
        this.setState({modal_view : view})
  }
  getDaysInMonth = (year, month) =>{
    return new Date(year, month, 0).getDate();
  }

  addMonths = (input, months) => {   
    months = isNaN(months) ? parseInt(-100) : months;
    const date = new Date(input)
    date.setDate(1)
    date.setMonth(date.getMonth() + months);
    date.setDate(Math.min(input.getDate(), this.getDaysInMonth(date.getFullYear(), date.getMonth()+1)));
    return date;
  }
  handleEndChange = (date) => {
      if(date < this.state.startDate){
        alert('End date must be greater than start date');
        return;
      }
      this.setState({
        endDate: date
      });
  }
  changeDateFilter = (event)=>{
    event.preventDefault();
    this.setState({ddldate_val: event.target.value});
  }
  handleStartChange = (date) => {
      if(date > this.state.endDate){
        alert('Start date must be smaller than end date');
        return;
      }
      this.setState({
        startDate: date
      });
  }
  exportToCSV  = (event)=>{
    event.preventDefault();
    var cur_table_data = Store.getStoreData('table_cur_data');
    var cur_table_arr = [];
    for(let i = 0; i<  cur_table_data.length; i++){
      var container = [];
      container = Object.values(cur_table_data[i])
      if(container.lenght > 0); container.shift(); container.pop() 
      cur_table_arr.push(container)
    }
    let csvContent = "data:text/csv;charset=utf-8," 
        + cur_table_arr.map(e => e.join("  ")).join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "Email_Log.csv");
    document.body.appendChild(link); 
    link.click();
  }
  applyFilter = (date_obj) => {
    console.log("date_obj", date_obj);
    var startDate = new Date(date_obj.from_date);
    var endDate = new Date(date_obj.end_date);
    let from_date = startDate.getFullYear() +'/'+ ('0' + (startDate.getMonth()+1)).slice(-2) + '/' +('0' + startDate.getDate()).slice(-2) 
    let to_date = endDate.getFullYear() +'/'+ ('0' + (endDate.getMonth()+1)).slice(-2) + '/' +('0' + endDate.getDate()).slice(-2) 
    let filter={};
    filter['from_date'] = from_date;
    filter['to_date'] = to_date;
    filter['task_id'] = date_obj.task_id;
    this.fetchEmailLog(filter,0);
    this.setState({apply_filter: filter});
  }

  clearFilter = () => {
    console.log("date_obj", this.state.apply_filter);
    this.fetchEmailLog(null, 1);
    this.setState({apply_filter: null});
  }

  exportToExcel = () => {
	let excel_arr = [];
	let fileName = 'email_log';
	let table_data = this.processAcceptanceQueueData().data;
	//console.log("table_data,",table_data);
	for(let d of table_data){
	  let elm ={
			'Module': d.module_id,
			'Suject': d.subject,
			'Recipient': d.recipient_name,
			'Recipient Email': d.recipient_email,
			'Status': d.status,
			'Re-Sent': d.resent,
			'Sent Date': d.sent_date,
	  }
	  excel_arr.push(elm);
	}

	let wb = {SheetNames:[], Sheets:{}};
	const ws1 = XLSX.utils.json_to_sheet(excel_arr); 
	wb.SheetNames.push("Email Log"); 
	wb.Sheets["Email Log"] = ws1;

	const format1 = "DD-MM-YYYY HH:mm:ss"
	let tm = moment().format(format1);

	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
	const data = new Blob([excelBuffer], {type: fileType});
	FileSaver.saveAs(data, fileName + '-' + tm + fileExtension);
	
  }
  render()
	{ 
  let headerText = "General Email Log";
  let show = ""
  //show = this.state.m === 0 ? "(showing top 1000)": "("+ "showing "+ ( parseInt(this.state.m)) +")";
  if(this.state.module_id !== 'general') {

    headerText = typeof Source_Map[this.state.module_id] === "undefined" ? this.state.module_id + " Email Log" : Source_Map[this.state.module_id] + " Email Log "+ show;
  }
 
		return (
            <div style={{display:"inline-block" , width:"84%", height:"900px" ,backgroundColor:"#ffffff"}}>
                <div style={{position:"absolute", padding: "17px" }}> 
                    {/*{
                        (() => {
                            if(this.state.ddldate_val == 10000) {
                                 return ( <div style={{position: "absolute", marginLeft:"331px", marginTop:"1px", zIndex: "100"}}>
                                  <div style={{display:"inline-block"}}>
                                              <label style={{paddingLeft:"10px", color: "#9e9e9e", fontWeight: "600"}}>Start Date : </label>
                                              <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.startDate} onChange={this.handleStartChange}/>
                                  </div>
                                  <div style={{display:"inline-block"}}>
                                              <label style={{paddingLeft:"10px", color: "#9e9e9e", fontWeight: "600"}}>End Date : </label>
                                              <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.endDate} onChange={this.handleEndChange}/>
                                  </div>
                                              

                                              
                                          </div>);
                            }
                        })()
                    }
                    <div style={{float:"right"}}>
                        {
                          (() => {
                            if(this.state.module_id === 'general') {
                              return(                      
                                <Dropdownlist id = "ddlmodule" onChange={this.changeModuleFilter} value={this.state.ddlmodule_val}>
                                <option value="0">All</option>
                                <option value="filechecker">File Checker</option>
                                <option value="rcm">RCM</option>
                                <option value="car">CAR</option>
                                <option value="cmp">CMP</option>
                            </Dropdownlist> )
                            }
                          })()
                        }
                    
                        <Dropdownlist id = "ddldate" onChange={this.changeDateFilter} value={this.state.ddldate_val}>
                            <option value="1000">Any date</option>
                            <option value="1">Last Month</option>
                            <option value="6">Last Six Months</option>
                            <option value="12">Last Year</option>
                            <option value="10000">Custom</option>
                        </Dropdownlist>     
                    </div>*/}
                      {
                        (() => {
                          if(Store.getStoreData('emaillog_data') === null) {
                            return <CSLLoader />
                          }

                        })()
                      }  
                      <div style={{clear: "both"}}></div>
                      <div style={{boxSizing: "border-box", padding: "15px 10px 0px 10px"}}>
                          <AdvancedFilter applyFilter={this.applyFilter} clearFilter={this.clearFilter}/>
                      </div>  
                      {
                        (() => {
                          if(this.state.overloaded){
                            return <p>Too many rows returned. Please refine your filter.</p>;
                          }
                        })()
                      }   
                      {
                        (() => {
                          if(this.state.errorloaded) {
                            return <p> Please chnage your filter. </p>;
                          }
                        })()
                      }                      
                    <CSLTable add={false} processData={this.processAcceptanceQueueData} headerText={headerText} tableRows={20} export={true} prefered_columns={this.state.prefered_columns} export_file_name={'Email_Log'} offset={true} exportToExcel={this.exportToExcel}/>    
                </div>
                {
                    (() => {
                        if(this.state.modal_view !== null) {
                            return <div style={{position:'absolute', left:'10%', top:'50px', width:'100%', height:'1186px'}}><InactiveOverlay /><EmailModal refreshParent={this.refreshState} modalData = {this.state.modal_data}/></div>;
                        }
                    })()
                }
            </div>         		  	 
		);
	}
}

export default EmailLog;