import React from 'react';
import {IoIosArrowUp, IoIosArrowDown, IoIosClose, IoIosTrash} from 'react-icons/io';
import { Icon } from 'react-icons-kit';
import {androidRemove} from 'react-icons-kit/ionicons/androidRemove';
import styled from 'styled-components';
import CslDatepicker1 from './CslDatepicker1';
import CslDatepicker2 from './CslDatepicker2';

const FilterTopBar = styled.div`
	background-color: #ffffff;
	font-weight: 600;
	color: #282828;
`;
const FilterText = styled.div`
	float: left;
`;
const FilterChevron = styled.div`
	font-size: 16px;
	cursor: pointer;
`;
const FilterBody = styled.div`
	background-color: #ffffff;
	margin-top: 2px;
	width: 100%;
	box-sizing: border-box;
`;
const Section45 = styled.div`
	float: left;
	width: 45%;
	box-sizing: border-box;
`;
const Section40 = styled.div`
	float: left;
	width: 40%;
	box-sizing: border-box;
	padding-right: 20px;
	position: relative;
`;
const Section20 = styled.div`
	float: left;
	width: 20%;
	box-sizing: border-box;
`;
const Section10 = styled.div`
	float: left;
	width: 10%;
	text-align: center;
	box-sizing: border-box;
	padding-top: 10px;
`;
const FieldLabel = styled.div`
	font-weight: 600;
	color: #282828;
	padding-bottom: 15px;
`;
const FilterClearBtn = styled.button`
	background-color: #ffffff;
	border: 1px solid #DBDBDB;
	border-radius: 4px;
	color: #282828;
	float: left;
	padding: 10px 13%;
	cursor: pointer
`;
const FilterApplyBtn = styled.button`
	background-color: #4FB2AF;
	border: 1px solid #4FB2AF;
	border-radius: 4px;
	color: #ffffff;
	float: right;
	padding: 10px 18%;
	cursor: pointer
`;

const ModalNameInputDiv = styled.div`
    height: auto;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const ModalInput = styled.input`
    height: 26px;
    border: 1px solid #DBDBDB;
    border-radius: 4px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;

class AdvancedFilter extends React.Component
{

	state = {show_filter: false, start_date:new Date(), end_date:new Date(), task_id: ''};


	toggleFilter = (event) => {
		event.preventDefault();
		let show_filter = this.state.show_filter ? false : true;
		this.setState({show_filter});
	}

	applyFilter = (event) => {
		event.preventDefault();
		let filter = {};
		filter.from_date = this.state.start_date;
		filter.end_date = this.state.end_date;
		filter.task_id = this.state.task_id;
		this.props.applyFilter(filter);
	}

	clearFilter = (event) => {
		event.preventDefault();
		this.props.clearFilter();
	}

	showfromDateChange = (date) => {
		this.setState({ start_date: date });
	}

	showendDateChange = (date) => {
		this.setState({ end_date: date });
	}

	handleChange = (event) => {
	    this.setState({ [event.target.name] : event.target.value })
	}

	render()
	{
		return (
			<div>
				<FilterTopBar>
					<FilterText>Advance Filter</FilterText>
					<FilterChevron onClick={this.toggleFilter}>
					{
						(() => {
							if (this.state.show_filter) {
								return <IoIosArrowUp />
							} else {
								return <IoIosArrowDown />
							}
						})()
					}
					</FilterChevron>
					<div style={{clear: "both"}}></div>
				</FilterTopBar>
				{
					(() => {
						if (this.state.show_filter) {
							return (
								<FilterBody>
									<Section40>
										<FieldLabel style={{marginTop: "20px"}}>Date Search</FieldLabel>
										<Section45><CslDatepicker1 showfromDateChange = {this.showfromDateChange} /></Section45>
										<Section10><Icon icon={androidRemove} /></Section10>
										<Section45><CslDatepicker2  showendDateChange = {this.showendDateChange} /></Section45>
									</Section40>
									<Section10 style={{paddingTop: "53px"}}>OR</Section10>
									<Section40>
										<FieldLabel style={{marginTop: "20px"}}>Task IDs(comma separated e.g. CAR-00xxx1,CAR-01xxxx2)</FieldLabel>
										<Section45>
											<ModalInput name="task_id" value={this.state.task_id} onChange ={this.handleChange} />
										</Section45>
									</Section40>
									<div style={{clear: "both"}}></div>
									<Section20>
										<div style={{paddingTop: "20px"}}>
											<FilterClearBtn onClick={this.clearFilter}>Clear</FilterClearBtn>
											<FilterApplyBtn onClick={this.applyFilter}>Search</FilterApplyBtn>
											<div style={{clear: "both"}}></div>
										</div>
									</Section20>
									<div style={{clear: "both"}}></div>
								</FilterBody>
							);
						}
					})()
				}
			</div>
		);
	}
}

export default AdvancedFilter;

