import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store';
import CSLIndex from './CSLIndex';
import SelectIndex from './SelectIndex';


const NoPermission = styled.div`
  display: block;
  box-sizing: border-box;
  height: 40px;
  font-size:20px;
  font-weight: 700;
  text-align: center;
  background-color: #ffffff;
  position: absolute;
  top: 100px;
  left: 10px;
  right:10px;
  bottom : 10px;
  border: 2px solid #37ada7;
  border-radius:10px;
  color: #37ada7;
  z-index: 1000;
`;

class Index extends React.Component
{
    state = { view_count: 0, cur_view: null }

    constructor(props) {
        super(props);
    }
    
    componentDidMount = () => {
        let cur_view = null;
        let is_admin = Store.getStoreData('is_admin');
        cur_view  = is_admin ? 'csl_index' : 'select_index';
        this.setState({cur_view : cur_view});
    }
    
    render() { 
         
        return(
            <div style={{backgroundColor: "#f2f2f3", position: "relative"}}>   
            {
                (() => {                    
                    if(this.state.cur_view) {
                        switch(this.state.cur_view) {
                            case 'csl_index' : return <CSLIndex />; break;
                            case 'select_index' : return <SelectIndex />; break;                          
                        }
                    }                  
                })()
            }
            </div>
        );
    }
}

export default Index;