import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import moment from 'moment';
import * as Style from './Common/StyledComponents';
import {FaAngleRight} from 'react-icons/fa';
import CSLTable from './Common/CSLTable.js'
import APICall from '../Common/APICall.js';
import Utils from '../Common/Utils';
import EmailModal from './EmailModal'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CSLLoader from './Common/CSLLoader';
import AdvancedFilter from './Partials/AdvancedFilter';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import ComDecom from '../Common/ComDecom.js';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const Dropdownlist = styled.select`
   height: 20px;
   border-radius: 4px;
   width: 150px;
   margin-left: 30px;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
`;
const Source_Map = {filechecker: 'File Checker', car: 'Compliance Advice Register', rcm: 'Regulatory Change Management', cmp: 'Compliance Monitoring Programme', conflicts:'Conflicts Register', tmview: 'Generic', gifts: 'Gifts',attest: 'Policy Attestation',breach: 'Incident Register' , committees : "Governance", docmgmt: "Document Management"}

const breach_modules_curlane = {'COMP_BREACH_USERSAVE': 'COMP_INCIDENT_USERSAVE', 'COMP_BREACH_REGISTER': 'COMP_INCIDENT_REGISTER', 'COMP_BREACH_COMPLETE': 'COMP_INCIDENT_COMPLETE', 'COMP_BREACH_FOLLOWUP_ASSIGNMENT': 'COMP_INCIDENT_FOLLOWUP_ASSIGNMENT', 'COMP_BREACH_REMEDIAL_ASSIGNMENT': 'COMP_INCIDENT_REMEDIAL_ASSIGNMENT', 'COMP_BREACH_PREVENTATIVE_ASSIGNMENT': 'COMP_INCIDENT_PREVENTATIVE_ASSIGNMENT',
'COMP_BREACH_FOLLOWUP_COMPLETE': 'COMP_INCIDENT_FOLLOWUP_COMPLETE', 'COMP_BREACH_REMEDIAL_COMPLETE': 'COMP_INCIDENT_REMEDIAL_COMPLETE', 'COMP_BREACH_PREVENTATIVE_COMPLETE': 'COMP_INCIDENT_PREVENTATIVE_COMPLETE', 'COMP_BREACH_REGISTER_ACTION': 'COMP_INCIDENT_REGISTER_ACTION',
'breach_of_rules': 'Incident', 'breach':'incident', 'COMP_BREACH': 'COMP_INCIDENT', 'COMP_BREACH_FOLLOWUP': 'COMP_INCIDENT_FOLLOWUP', 'COMP_BREACH_DELETE': 'COMP_INCIDENT_DELETE'};

class DataChangeLog extends React.Component
{
	state = { 
                data_change_log : {},
                client_name : '',
                ddlmodule_val : 0,
                ddldate_val : 'anydate',
                startDate: new Date(),
                endDate: new Date(),
                module_id: 'general',
                prefered_columns: null,
                view_count: 0,
                apply_filter: null,
                overloaded: false,
                errorloaded: false
	}
    
  /*componentDidMount = () => {
        this.setState({data_change_log : this.props.data_change_log, client_name : this.props.client_name, module_id: this.props.module_id})      
  }*/

  componentDidMount = () => {  
    console.log("this.state.apply_filter", this.state.apply_filter)
    this.fetchDatachangeLog(this.state.apply_filter, 0);  
  }
  componentDidUpdate(prevProps)
  {
    if (prevProps !== this.props) {
       this.fetchDatachangeLog(this.state.apply_filter, 0);   
    }
  }

  loadTop1000 = (n) => {
    this.setState({n: n})
    this.fetchDatachangeLog(n, null);
  }
  
  loadMore = (n) => {
    let offset = this.state.n;
    n = offset + n;
    this.setState({n: n})
    this.fetchDatachangeLog(n, null);
  }

  fetchDatachangeLog_obsalute = () => {
      let module_id = this.props.module_id;
      console.log("module_id", module_id)
      let postData = { command: "getdatachangelog", module: module_id };
      let api = new APICall();
      api.command(postData, this.processData);
      this.setState({module_id: this.props.module_id})
  }

  fetchDatachangeLog = (filter, clear) => {
    // console.log("filter", filter);
    let module_id = this.props.module_id;
    let postData = {};
    let api = new APICall();
    if(Store.getStoreData('data_change_log') === null || filter !== null || clear === 1) {
      postData['command'] = 'getdatachangelog';
      postData['module'] = module_id;
      postData['filter'] = filter;
      console.log("postData", postData);
      api.command(postData, this.processData)
    }
    let prefered_columns ={};
    prefered_columns['module_id'] = 'Module';
    prefered_columns['item'] = 'Item';
    prefered_columns['from'] = 'From';
    prefered_columns['to'] = 'To';
    prefered_columns['changed_by'] = 'Changed By';
    prefered_columns['changed_date'] = 'Changed Date';
    this.setState({module_id: this.props.module_id,client_name : this.props.client_name, prefered_columns: prefered_columns})
  }

  processData = (result) => {
    console.log("data_change_log", result);
    let overloaded = this.state.overloaded;
    let errorloaded = this.state.errorloaded;
    if(result.error_code === 0){
      let cl = ComDecom.deCompress(result.result, false, 'id');
      console.log("cl", cl)
      Store.updateStore('data_change_log', cl);
      overloaded = false;
      errorloaded = false;
    }else if(result.error_code === 11111){
      overloaded = true;
      errorloaded = false;
    }else{
      errorloaded = true;
      overloaded = false;
    }
    
    let m = result.length < 1000 ?  parseInt(this.state.n) + parseInt(result.length) : this.state.m;
    this.setState({view_count : this.state.view_count + 1, m:m, overloaded: overloaded, errorloaded: errorloaded});
  }


  calculateDateInTimestamp = function (inputDate) {
        var date_input = new Date(inputDate);
        var date_output = date_input.getDate() + '/' + (date_input.getMonth() + 1) + '/' + date_input.getFullYear()
        return date_output;
  }
  getGroupCompanies =(company_id)=>{
        var group_company_id = parseInt(company_id) % 100000;
        let group_company_name ='';
        let gc_companies = this.props.gc_companies;
        let grp ={};    
        if(gc_companies.length > 0){
            for(var k = 0 ; k<gc_companies.length; k++){          
              grp[gc_companies[k].id] = gc_companies[k].company_name;
            }
        }
        group_company_name = grp[group_company_id]
        return group_company_name;
  }

  getDate = (input_date) => {
    if(input_date != null){
      let dateStr = input_date.split('T')[0];
      let dateParts = dateStr.split('-'); 
      let ret = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
      return ret;
    }else{
      return '01/01/1970';
    }
   
  }

  processAcceptanceQueueData = () => {
        let lane_end = '';
        let dataCahngeLog = Store.getStoreData('data_change_log') === null ? [] : Store.getStoreData('data_change_log');
        // console.log("Result_changelog", dataCahngeLog[0]);
        let ret = {data: [], columns: []};
        let i = 1;
        ret.columns =[{Header: 'Module', accessor: 'module_id', minWidth: 170, headerStyle: {textAlign: 'left'}},
                      {Header: 'Item', accessor: 'item', minWidth: 270, headerStyle: {textAlign: 'left'}},      
                      {Header: 'From', accessor: 'from', minWidth: 170, headerStyle: {textAlign: 'left'}},
                      {Header: 'To', accessor: 'to', minWidth: 230, headerStyle: {textAlign: 'left'}},
                      {Header: 'Changed By', accessor: 'changed_by', minWidth: 120, headerStyle: {textAlign: 'left', visible:'false'}},
                      {Header: 'Changed Date', accessor: 'changed_date', minWidth: 120, headerStyle: {textAlign: 'left', visible:'false'}},
                    ];
                    /*for(let k = 0 ; k< dataCahngeLog.length ; k++) 
                    {
                        let changed_by = ('staff_id' in dataCahngeLog[k] && dataCahngeLog[k].staff_id > 0) ? this.props.staff_list[dataCahngeLog[k].staff_id] : this.props.contact_list[dataCahngeLog[k].contact_id];
                        let updated_at_arr = this.calculateDateInTimestamp(dataCahngeLog[k].updated_at).split('/')
                        let updated_at = new Date(dataCahngeLog[i].updated_at);
                        let compare_date = this.addMonths(new Date(), -parseInt(this.state.ddldate_val));
                        let start_date = this.state.startDate;
                        let endDate = this.state.endDate;
                        let updated_at_int = parseInt(updated_at_arr[2]) * 10000 + parseInt(updated_at_arr[1]) *100 + parseInt(updated_at_arr[0]);
                        let compare_date_int = compare_date.getFullYear() * 10000 + (compare_date.getMonth() +1) *100 + compare_date.getDate();
                        if(this.state.ddldate_val == 10000){
                          let start_date_int = start_date.getFullYear() * 10000 + (start_date.getMonth() +1) * 100 + start_date.getDate();
                          let end_date_int = endDate.getFullYear() * 10000 + (endDate.getMonth() +1) * 100 + endDate.getDate();

                          if(updated_at_int>= start_date_int && updated_at_int <= end_date_int){
                              let elem = {'index': k,
                                          'module_id': Source_Map[dataCahngeLog[k].module_id],
                                          'item' : dataCahngeLog[k].alt_field_name,
                                          'from' : dataCahngeLog[k].field_value_prev,
                                          'to' : dataCahngeLog[k].field_value,
                                          'changed_by' : changed_by,
                                          'changed_date' : this.calculateDateInTimestamp(dataCahngeLog[k].updated_at),
                                         };
                              ret.data.push(elem);
                          }
                        }else if(updated_at_int >= compare_date_int){
                              let elem = {'index': k,
                                          'module_id': Source_Map[dataCahngeLog[k].module_id],
                                          'item' : dataCahngeLog[k].alt_field_name,
                                          'from' : dataCahngeLog[k].field_value_prev,
                                          'to' : dataCahngeLog[k].field_value,
                                          'changed_by' : changed_by,
                                          'changed_date' : this.calculateDateInTimestamp(dataCahngeLog[k].updated_at),
                                         };
                              ret.data.push(elem);
                        }
                    }*/
                    for(let k = 0 ; k< dataCahngeLog.length ; k++) {
                      let changed_by = ('staff_id' in dataCahngeLog[k] && dataCahngeLog[k].staff_id > 0) ? this.props.staff_list[dataCahngeLog[k].staff_id] : this.props.contact_list[dataCahngeLog[k].contact_id];
                      let changed_date = this.getDate(dataCahngeLog[k].updated_at)
                      let field_value = dataCahngeLog[k].field_value;
                      /*if(Utils.isBase64(field_value)){
                          field_value = window.decodeURIComponent(atob(field_value))
                      }*/
                      let elem = {'index': k,
                                  'module_id': Source_Map[dataCahngeLog[k].module_id],
                                  'item' : dataCahngeLog[k].alt_field_name,
                                  'from' : breach_modules_curlane[dataCahngeLog[k].field_value_prev] ? breach_modules_curlane[dataCahngeLog[k].field_value_prev] : dataCahngeLog[k].field_value_prev,
                                  'to' : breach_modules_curlane[field_value] ? breach_modules_curlane[field_value] : field_value,
                                  'changed_by' : changed_by,
                                  'changed_date' : changed_date,
                                 };
                      ret.data.push(elem);
                    }
        Store.updateStore('table_changed_data', ret.data)
        return ret;      
  }
  refreshState = (view) =>{
        this.setState({modal_view : view})
  }
  getDaysInMonth = (year, month) =>{
    return new Date(year, month, 0).getDate();
  }

  addMonths = (input, months) => {   
    months = isNaN(months) ? parseInt(-100) : months;
    const date = new Date(input)
    date.setDate(1)
    date.setMonth(date.getMonth() + months);
    date.setDate(Math.min(input.getDate(), this.getDaysInMonth(date.getFullYear(), date.getMonth()+1)));
    return date;
  }
  handleEndChange = (date) => {
      if(date < this.state.startDate){
        alert('End date must be greater than start date');
        return;
      }
      this.setState({
        endDate: date
      });
  }
  changeDateFilter = (event)=>{
    event.preventDefault();
    this.setState({ddldate_val: event.target.value});
  }
  handleStartChange = (date) => {
      if(date > this.state.endDate){
        alert('Start date must be smaller than end date');
        return;
      }
      this.setState({
        startDate: date
      });
  }
  exportToCSV  = (event)=>{
    event.preventDefault();
    var count = 0;
    var cur_table_data = Store.getStoreData('table_changed_data');
    //console.log("cur_table_data.length", cur_table_data)
    var cur_table_arr = [];
    for(let i = 0; i<  cur_table_data.length; i++){
      
      var container = [];
      container = Object.values(cur_table_data[i])
      if(container.lenght > 0); container.shift(); 
      
      cur_table_arr.push(container);
      count ++;
    }
    console.log("cur_table_data.length", count)
    if(cur_table_arr.length == cur_table_data.length)
    {
      let csvContent = "data:text/csv;charset=utf-8," 
          + cur_table_arr.map(e => e.join("  ")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Changed_Log.csv");
      document.body.appendChild(link); 
      link.click();
    }
  }
  applyFilter = (date_obj) => {
    // console.log("date_obj", date_obj);
    var startDate = new Date(date_obj.from_date);
    var endDate = new Date(date_obj.end_date);
    let from_date = startDate.getFullYear() +'/'+ ('0' + (startDate.getMonth()+1)).slice(-2) + '/' +('0' + startDate.getDate()).slice(-2) 
    let to_date = endDate.getFullYear() +'/'+ ('0' + (endDate.getMonth()+1)).slice(-2) + '/' +('0' + endDate.getDate()).slice(-2) 
    let filter={};
    filter['from_date'] = from_date;
    filter['to_date'] = to_date;
    filter['task_id'] = date_obj.task_id;
    this.fetchDatachangeLog(filter, 0);
    this.setState({apply_filter: filter});
  }

  clearFilter = () => {
    console.log("date_obj", this.state.apply_filter);
    this.fetchDatachangeLog(null, 1);
    this.setState({apply_filter: null});
  }
  exportToExcel = () => {
	let excel_arr = [];
	let fileName = 'datachange_log';
	let table_data = this.processAcceptanceQueueData().data;
	//console.log("table_data,",table_data);
	for(let d of table_data){
	  let elm ={
			'Module': d.module_id,
			'Item': d.item,
			'From': d.from,
			'To': d.to,
			'Changed By': d.changed_by,
			'Changed Date': d.changed_date,
	  }
	  excel_arr.push(elm);
	}

	let wb = {SheetNames:[], Sheets:{}};
	const ws1 = XLSX.utils.json_to_sheet(excel_arr); 
	wb.SheetNames.push("Datachange_Log"); 
	wb.Sheets["Datachange_Log"] = ws1;

	const format1 = "DD-MM-YYYY HH:mm:ss"
	let tm = moment().format(format1);

	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
	const data = new Blob([excelBuffer], {type: fileType});
	FileSaver.saveAs(data, fileName + '-' + tm + fileExtension);
	
  }
	render()
	{

    let headerText = "General Change Log";
    let show = ""
    //show = this.state.m === 0 ? "(showing top 1000)": "("+ "showing "+ ( parseInt(this.state.m)) +")";
    if(this.state.module_id !== 'general') {
      headerText = typeof Source_Map[this.state.module_id] === "undefined" ? this.state.module_id + " Change Log" : Source_Map[this.state.module_id] + " Change Log "+ show;
    }  

    // console.log("this.state.m", this.state.apply_filter)  
    console.log("this.state.m", this.state.n)   
		return (
            <div style={{display:"inline-block" , width:"84%", height:"900px" ,backgroundColor:"#ffffff"}}>
                <div style={{position:"absolute", padding: "17px" }}>    
                    {
                      (() => {
                        if(Store.getStoreData('data_change_log') === null) {
                          return <CSLLoader />
                        }

                      })()
                    }  

                    <div style={{clear: "both"}}></div>
                    <div style={{boxSizing: "border-box", padding: "15px 10px 0px 10px"}}>
                        <AdvancedFilter applyFilter={this.applyFilter} clearFilter={this.clearFilter}/>
                    </div>     
                    <div style={{clear: "both"}}></div>  
                    {
                      (() => {
                        if(this.state.overloaded){
                          return <p>Too many rows returned. Please refine your filter.</p>;
                        }
                      })()
                    } 
                    {
                      (() => {
                        if(this.state.errorloaded) {
                          return <p> Please chnage your filter. </p>;
                        }
                      })()
                    }                 
                    <CSLTable add={false} processData={this.processAcceptanceQueueData} headerText={headerText} tableRows={20} export={true} prefered_columns={this.state.prefered_columns} export_file_name={'Datachange_Log'} exportToExcel={this.exportToExcel} offset={true} loadMore={this.loadMore} loadTop1000={this.loadTop1000} n={this.state.n}/>  
                </div>
            </div>         		  	 
		);
	}
}

export default DataChangeLog;