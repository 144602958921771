import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import moment from 'moment';
import * as Style from './Common/StyledComponents';
import {FaAngleRight} from 'react-icons/fa';
import CSLTable from './Common/CSLTable.js'
import APICall from '../Common/APICall.js';
import ActivityCompletedModal from './ActivityCompletedModal'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CSLLoader from './Common/CSLLoader';
import AdvancedFilter from './Partials/AdvancedFilter';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #01B0A2;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 100px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
    margin-right:3px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 1178px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: -10%;
  top: -80px;
  z-index: 1000;
  opacity: 0.8;
`;
const Dropdownlist = styled.select`
   height: 20px;
   border-radius: 4px;
   width: 150px;
   margin-left: 30px;
`;
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin-right: 3px;
    margin-top: 19px;
`;

const Source_Map = {filechecker: 'File Checker', car: 'Compliance Advice Register', rcm: 'Regulatory Change Management', cmp : 'Compliance Monitoring Programme',conflicts: 'Conflicts Register', tmview: 'Generic', gifts: 'Gifts', attest: 'Policy Attestation', breach: 'Incident Register' , committees : 'Governance', padreg: 'PA Dealings', docmgmt: 'Document Library'}

const Activity_Shown = {
  COMP_FCK_CREATE: 1, COMP_FCK_COMPLETE: 1, COMP_FCK_QC_ASSIGNMENT : 1, COMP_FCK_QC_COMPLETE: 1, 
  COMP_CAR_CREATE: 1,COMP_CAR_ANSWER: 1,COMP_CAR_ACCEPT: 1,
  COMP_RCM_CREATE: 1, COMP_RCM_ASSIGNMENT: 1,COMP_RCM_ACTION: 1,COMP_RCM_FOLLOWUP_COMPLETE:1,COMP_RCM_FOLLOWUP_ASSIGNMENT:1,COMP_RCM_COMPLETE:1,
  COMP_CMP_CREATE: 1, COMP_CMP_SAVED: 1 , COMP_CMP_ASSIGNMENT: 1,COMP_CMP_FOLLOWUP_ASSIGNMENT:1,COMP_CMP_FOLLOWUP_COMPLETE:1,COMP_CMP_FOLLOWUP_SAVE:1,COMP_CMP_FOLLOWUP_SAVED:1,COMP_CMP_COMPLETED:1,
  COMP_CONFLICTS_REGISTER:1,COMP_COI_FOLLOWUP:1,COMP_COI_FOLLOWUP_DELETE:1,COMP_CONFLICTS_COMPLETE:1,COMP_COI_REMOVE:1,COMP_CONFLICTS_DELETE:1,
  COMP_GENERIC_CREATE: 1, COMP_GENERIC_UPDATE: 1, COMP_GENERIC_COMPLETED : 1,
  COMP_GIFTS_REGISTER:1,COMP_GIFTS_FOLLOWUP:1,COMP_GIFTS_FOLLOWUP_DELETE:1,COMP_GIFTS_COMPLETE:1,COMP_GIFTS_REMOVE:1,COMP_GIFTS_DELETE:1,
  COMP_ATTESTATION_ASSIGNMENT:1, COMP_ATTESTATION_ASSIGNMENT_SHARE:1, COMP_ATTESTATION_COMPLETED_SHARE:1,COMP_ATTESTATION_REMOVE:1, COMP_ATTESTATION_REMOVE_SHARE:1,
  COMP_BREACH_REGISTER:1,COMP_BREACH_FOLLOWUP_ASSIGNMENT:1,COMP_BREACH_REMEDIAL_ASSIGNMENT:1,COMP_BREACH_PREVENTATIVE_ASSIGNMENT:1,COMP_BREACH_FOLLOWUP_DELETE:1,COMP_BREACH_REGISTER_ACTION:1,COMP_BREACH_COMPLETE:1,COMP_BREACH_DELETE:1,
  COMP_COMMITTEES_CREATE: 1, COMP_COMMITTEES_SAVED: 1 , COMP_COMMITTEES_ASSIGNMENT: 1, COMP_COMMITTEES_COMPLETE:1, CREATE_MEETING:1 , COMPLETE_MEETING:1, DELETE_MEETING:1, CREATE_COMMITTEE:1, DELETE_COMMITTEE:1 ,
  COMP_PADREG_REGISTERED: 1, COMP_PADREG_COMPLETED: 1, COMP_PADREG_DELETED: 1, COMP_PADREG_INPROGRESS: 1,
  COMP_DOC_FOLLOWUP_CREATE: 1,COMP_DOC_FOLLOWUP_COMPLETE: 1, 'COMP_DOC_FOLLOWUP_UPDATE': 1, CREATE_DOCUMENT: 1,
  UPDATE_DOCUMENT: 1,UNPINN_DOCUMENT:1, PINN_DOCUMENT: 1, RESTORE_DOCUMENT: 1, MOVE_DOCUMENT: 1, DELETE_DOCUMENT: 1
};
const Activity_Text = {'COMP_FCK_CREATE': 'Filecheck Assigned', 'COMP_FCK_COMPLETE': 'Filecheck Completed', 
'COMP_FCK_QC_ASSIGNMENT' : 'Filecheck Quality Assurance Assigned', 'COMP_FCK_QC_COMPLETE': 'Filecheck Quality Assurance Completed',
'COMP_CAR_CREATE' : 'Create Advice request', 'COMP_CAR_ANSWER': 'Manager or CA answer the advice request', 'COMP_CAR_ACCEPT': 'Acknowledge the advice request',
'COMP_CONFLICTS_REGISTER' : 'Create a Conflict', 'COMP_COI_FOLLOWUP' : 'Conflict Register Followup assignment','COMP_COI_FOLLOWUP_DELETE' : 'Conflict Register Followup Deleted', 'COMP_CONFLICTS_COMPLETE': 'Conflict Register Completed', 'COMP_COI_REMOVE': 'Conflict Register Removed', 'COMP_CONFLICTS_DELETE': 'Conflict Register Deleted',
'COMP_CMP_CREATE' : 'Create Compliance Monitoring Programme' , 'COMP_CMP_SAVED' : 'Save Compliance Monitoring Programme' ,  'COMP_CMP_ASSIGNMENT' : 'Assign Compliance Monitoring Programme', 'COMP_CMP_FOLLOWUP_ASSIGNMENT' : 'Followup Assign Compliance Monitoring Programme', 'COMP_CMP_FOLLOWUP_COMPLETE': 'Followup Compliance Monitoring Programme Completed', 'COMP_CMP_COMPLETED': 'Compliance Monitoring Programme Completed' ,
'COMP_RCM_CREATE': 'Create new Regulatory Change Management', 'COMP_RCM_ASSIGNMENT': 'Assign Regulatory Change Management','COMP_RCM_ACTION': 'Action taken Regulatory Change Management','COMP_RCM_COMPLETE': 'Regulatory Change Management Completed','COMP_RCM_FOLLOWUP_COMPLETE': 'Regulatory Change Management Followup completed','COMP_RCM_FOLLOWUP_ASSIGNMENT': 'Regulatory Change Management Followup assignment',
'COMP_GENERIC_CREATE': 'Create and assign new generic task', 'COMP_GENERIC_UPDATE': 'Modify generic task', 'COMP_GENERIC_COMPLETED': 'Generic task completed',
'COMP_GIFTS_REGISTER' : 'Create a Gifts & Hospitality Register', 'COMP_GIFTS_FOLLOWUP' : 'Gifts & Hospitality Register Followup assignment','COMP_GIFTS_FOLLOWUP_DELETE' : 'Gifts & Hospitality Register Followup Deleted', 'COMP_GIFTS_COMPLETE': 'Gifts & Hospitality Register Completed', 'COMP_GIFTS_REMOVE': 'Gifts & Hospitality Register Removed', 'COMP_GIFTS_DELETE': 'Gifts & Hospitality Register Deleted',
'COMP_ATTESTATION_ASSIGNMENT' : 'Create a Policy Attestation', 'COMP_ATTESTATION_ASSIGNMENT_SHARE' : 'Policy Attestation share user','COMP_ATTESTATION_COMPLETED_SHARE' : 'Policy Attestation share user complete', 'COMP_ATTESTATION_REMOVE': 'Policy Attestation remove', 'COMP_ATTESTATION_REMOVE_SHARE': 'Policy Attestation share user remove',
'COMP_BREACH_REGISTER' : 'Create a Incident Register', 'COMP_BREACH_FOLLOWUP_ASSIGNMENT' : 'Incident Register Followup assignment', 'COMP_BREACH_REMEDIAL_ASSIGNMENT' : 'Incident Register Remidial assignment', 'COMP_BREACH_PREVENTATIVE_ASSIGNMENT' : 'Incident Register Preventative assignment','COMP_BREACH_FOLLOWUP_DELETE' : 'Incident Register Followup Deleted', 'COMP_BREACH_REGISTER_ACTION' : 'Incident Register inprogress', 'COMP_BREACH_COMPLETE': 'Incident Register Completed', 'COMP_BREACH_DELETE': 'Incident Register Deleted',
'COMP_COMMITTEES_CREATE' : 'Create governance task' , 'COMP_COMMITTEES_SAVED' : 'Save governance task' ,  'COMP_COMMITTEES_ASSIGNMENT' : 'Assign governance task', 'COMP_COMMITTEES_COMPLETE': 'Governance task Completed' , 'CREATE_MEETING' : 'Create Meeting' , 'COMPLETE_MEETING': 'Complete Meeting' , 'DELETE_MEETING' : 'Delete Meeting' , 'CREATE_COMMITTEE' : 'Create Committee' , 'DELETE_COMMITTEE' : 'Delete Committee' ,
'COMP_PADREG_REGISTERED' : 'PA Dealing Registered', 'COMP_PADREG_COMPLETED': 'PA Dealing Completed', 'COMP_PADREG_DELETED': 'PA Dealing Deleted','COMP_PADREG_INPROGRESS' : 'PA Dealing in Progress',
'COMP_DOC_FOLLOWUP_CREATE' : 'Follow-up task Assigned', 'COMP_DOC_FOLLOWUP_COMPLETE' : 'Follow-up task  Completed', 'COMP_DOC_FOLLOWUP_UPDATE': 'Follow-up task Updated',
'CREATE_DOCUMENT': ' Document Added', 'UPDATE_DOCUMENT': ' Document Updated','UNPINN_DOCUMENT':'Document Unpinned', 'PINN_DOCUMENT': 'Document Pinned', 'RESTORE_DOCUMENT': 'Document Restored', 'MOVE_DOCUMENT': 'Document Moved',
'DELETE_DOCUMENT': 'Document Deleted'

};

class ActivityLog extends React.Component
{
	state = { 
                activitylog_data : {},
                client_name : '',
                modal_view : null,
                modal_data : {},
                ddlmodule_val : 0,
                ddldate_val : 'anydate',
                startDate: new Date(),
                endDate: new Date(),
                tableData: '',
                module_id: 'general',
                is_loaded: false,
                view_count: 0,
                prefered_columns: null,
                n : 0,
                m: 0,
                apply_filter: null,
                overloaded: false,
                errorloaded: false
			}
    
    loadMore = (n) => {
      let offset = this.state.n;
      n = offset + n;
      this.setState({n: n})
      this.fetchActivityLog(this.state.apply_filter, 0);
    }
    loadTop1000 = (n) => {
      this.setState({n: n})
      this.fetchActivityLog(this.state.apply_filter, 0);
    }

    componentDidMount() {        
        this.fetchActivityLog(this.state.apply_filter, 0);
    }

    fetchActivityLog = (filter, clear) => {
      let module_id = this.props.module_id;
      let postData = {};
      let api = new APICall();
      if(Store.getStoreData('activitylog_data') === null || filter!==null || clear === 1) {
        postData['command'] = 'getactivitylog';
        postData['module'] = module_id;
        postData['filter'] = filter;
        api.command(postData, this.processData)
      }
      let prefered_columns ={};
      prefered_columns['module_id'] = 'Module';
      prefered_columns['activity_type'] = 'Activity Type';
      prefered_columns['client_name'] = 'Client Name';
      prefered_columns['changed_by'] = 'Changed By';
      prefered_columns['changed_date'] = 'Changed Date';
      this.setState({client_name : this.props.client_name, module_id: this.props.module_id, prefered_columns: prefered_columns})
    }

    processData = (result) => {
        console.log("getactivitylog", result)
      let overloaded = this.state.overloaded;
      let errorloaded = this.state.errorloaded;
      if(result.error_code === 0){
        Store.updateStore('activitylog_data', result.my_tasks);
          overloaded = false;
          errorloaded = false;
        }else if(result.error_code === 11111){
          overloaded = true;
          errorloaded = false;
        }else{
          errorloaded = true;
          overloaded = false;
        }
      
      let m = this.state.m;
      this.setState({view_count : this.state.view_count + 1, m:m, overloaded: overloaded, errorloaded: errorloaded});
    }

    calculateDateInTimestamp = function (inputDate) {
        var date_input = new Date(inputDate);
        var date_output = ('0' + date_input.getDate()).slice(-2) + '/' + ('0' + (date_input.getMonth()+1)).slice(-2) + '/' + date_input.getFullYear()
        return date_output;
    }
    getGroupCompanies =(company_id)=>{
        var group_company_id = parseInt(company_id) % 100000;
        let group_company_name ='';
        let gc_companies = this.props.gc_companies;
        let grp ={};    
        if(gc_companies.length > 0){
            for(var k = 0 ; k<gc_companies.length; k++){          
              grp[gc_companies[k].id] = gc_companies[k].company_name;
            }
        }
        group_company_name = grp[group_company_id]
        return group_company_name;
    }
    openActivityModal = (task_id, module_id, client_name, changed_date) => (event) => {
        event.preventDefault();
        var task_data = {};
        for(let i= 0; i< Store.getStoreData('activitylog_data').length ; i++){
          task_data[Store.getStoreData('activitylog_data')[i].id] =Store.getStoreData('activitylog_data')[i];
        }
        var modal_data = {module_name : module_id , client_name : client_name, changed_date: changed_date, activitylog_data : task_data[task_id]}
        console.log("uu",modal_data);
        this.setState({modal_view : 'activitymodal', modal_data : modal_data})     
    }
   
    getDaysInMonth = (year, month) =>{
      return new Date(year, month, 0).getDate();
    }

    addMonths = (input, months) => {   
      months = isNaN(months) ? parseInt(-100) : months;
      const date = new Date(input)
      date.setDate(1)
      date.setMonth(date.getMonth() + months);
      date.setDate(Math.min(input.getDate(), this.getDaysInMonth(date.getFullYear(), date.getMonth()+1)));
      return date;
    }
    processAcceptanceQueueData = () => {
        let lane_end = '';
        let taskData = Store.getStoreData('activitylog_data') === null ? {} : Store.getStoreData('activitylog_data');
        var task_action_arr = []; 
        var task_action = {};
        for(let i = 0 ; i < taskData.length ; i ++){
            var taskjson = JSON.parse(taskData[i].task_json);
            var action_data = taskjson.action_data;   
            // console.log("Action Data", action_data);      
                for(let j= 0 ;j < action_data.length; j++){
                    if(action_data[j].action in Activity_Shown) {
                        let lane_parts = action_data[j].action.split('_')
                        let lane_end = lane_parts[lane_parts.length - 1]
                        task_action.module_id = taskData[i].module_id;
                        task_action.activity_type = action_data[j].action;                        
                        task_action.client_id = taskData[i].company_id;
                        if(lane_end == 'CREATE'){
                             task_action.changed_by = taskData[i].contact_id;
                        }else{
                             task_action.changed_by = ('performed_by' in action_data[j]) ? action_data[j].performed_by : 0;
                        }
                        task_action.changed_date = this.calculateDateInTimestamp(action_data[j].actiondate);
                        task_action.date_time_stamp = action_data[j].actiondate;
                        task_action.task_id = taskData[i].id
                        task_action_arr.push(task_action);
                        task_action = {};
                    }
                    
                }
        }

        console.log("task_action_arr", task_action_arr);

        let ret = {data: [], columns: []};
        let i = 1;
        ret.columns =[{Header: 'Module', accessor: 'module_id', minWidth: 170, headerStyle: {textAlign: 'left'}},
                      {Header: 'Activity Type', accessor: 'activity_type', minWidth: 370, headerStyle: {textAlign: 'left'}},      
                      {Header: 'Client Name', accessor: 'client_name', minWidth: 170, headerStyle: {textAlign: 'left'}},
                      {Header: 'Changed By', accessor: 'changed_by', minWidth: 170, headerStyle: {textAlign: 'left'}},
                      {Header: 'Changed Date', accessor: 'changed_date', minWidth: 170, headerStyle: {textAlign: 'left', visible:'false'}},
                      {'Header' : 'Action', Cell: row => (
                        <div>
                              {
                                (() => {
                                  var activty = row.original.activity_type.split(' ');
                                  var activity_end = activty[activty.length - 1];
                                  console.log("row.original", activty)
                                  if(activity_end == 'Completed') {
                                     return (<MRButton onClick={this.openActivityModal(row.original.task_id, row.original.module_id, row.original.client_name, row.original.changed_date)}>View</MRButton>);
                                   }

                                })()
                              }
                        </div>
                      ), width: 120, headerStyle: {textAlign: 'center'}},
                    ];
		//console.log("task_action_arr.length ==>"+task_action_arr.length );
        /*for(let k = 0 ; k< task_action_arr.length ; k++) 
        {
            let CompanyName = '';
            if(parseInt(task_action_arr[k].client_id) < 100000){
                CompanyName = this.state.client_name
            }else{
                CompanyName =this.getGroupCompanies(task_action_arr[k].client_id)
            }
            
            let changed_date = new Date(task_action_arr[k].date_time_stamp);
            let compare_date = this.addMonths(new Date(), -parseInt(this.state.ddldate_val));
            let start_date = this.state.startDate;
            let endDate = this.state.endDate
            let changed_date_int = changed_date.getFullYear() * 10000 + (changed_date.getMonth() +1) * 100 + changed_date.getDate();
            let compare_date_int = compare_date.getFullYear() * 10000 + (compare_date.getMonth() +1) *100 + compare_date.getDate();
            
            
            
            if(this.state.ddldate_val == 10000){
              let start_date_int = start_date.getFullYear() * 10000 + (start_date.getMonth() +1) * 100 + start_date.getDate();
              let end_date_int = endDate.getFullYear() * 10000 + (endDate.getMonth() +1) * 100 + endDate.getDate();

              if(changed_date_int>= start_date_int && changed_date_int <= end_date_int){
                let elem = {'index': k,
                        'module_id': Source_Map[task_action_arr[k].module_id],
                        'activity_type' : Activity_Text[task_action_arr[k].activity_type],
                        'client_name' : CompanyName,
                        'changed_by' : task_action_arr[k].changed_by === 0 ? '---' : this.props.contact_list[task_action_arr[k].changed_by],
                        'changed_date' : task_action_arr[k].changed_date,
                        'task_id' : task_action_arr[k].task_id   
                };
                ret.data.push(elem);
              }
              
            }
            else if(changed_date_int >= compare_date_int){
              let elem = {'index': k,
                        'module_id': Source_Map[task_action_arr[k].module_id],
                        'activity_type' : Activity_Text[task_action_arr[k].activity_type],
                        'client_name' : CompanyName,
                        'changed_by' : task_action_arr[k].changed_by === 0 ? '---' : this.props.contact_list[task_action_arr[k].changed_by],
                        'changed_date' : task_action_arr[k].changed_date,
                        'task_id' : task_action_arr[k].task_id   
              };
              ret.data.push(elem);
            }
        }*/
        for(let k = 0 ; k< task_action_arr.length ; k++) {
          let CompanyName = '';
          if(parseInt(task_action_arr[k].client_id) < 100000){
              CompanyName = this.state.client_name
          }else{
              CompanyName =this.getGroupCompanies(task_action_arr[k].client_id)
          }
          let activity_type = (task_action_arr[k].activity_type in Activity_Text) ? Activity_Text[task_action_arr[k].activity_type] : '---';
          let elem = {'index': k,
                    'module_id': Source_Map[task_action_arr[k].module_id],
                    'activity_type' : activity_type,
                    'client_name' : CompanyName,
                    'changed_by' : task_action_arr[k].changed_by === 0 ? '---' : this.props.contact_list[task_action_arr[k].changed_by],
                    'changed_date' : task_action_arr[k].changed_date,
                    'task_id' : task_action_arr[k].task_id   
          };
          ret.data.push(elem);
        }
        Store.updateStore('table_cur_data', ret.data)
        return ret;      
      }

      getDate = (input_date) => {
        if(input_date != null){
          let dateStr = input_date.split('T')[0];
          let dateParts = dateStr.split('-'); 
          let ret = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
          return ret;
        }else{
          return '01/01/1970';
        }
       
      }


      refreshState = (view) =>{
        this.setState({modal_view : view})
      }
      changeModuleFilter = (event)=>{
        event.preventDefault();
        this.setState({ddlmodule_val: event.target.value});
      }
      handleEndChange = (date) => {
          if(date < this.state.startDate){
            alert('End date must be greater than start date');
            return;
          }
          this.setState({
            endDate: date
          });
      }
      changeDateFilter = (event)=>{
        event.preventDefault();
        this.setState({ddldate_val: event.target.value});
      }
      handleStartChange = (date) => {
          if(date > this.state.endDate){
            alert('Start date must be smaller than end date');
            return;
          }
          this.setState({
            startDate: date
          });
      }

      applyFilter = (date_obj) => {
        console.log("date_obj", date_obj);
        var startDate = new Date(date_obj.from_date);
        var endDate = new Date(date_obj.end_date);
        let from_date = startDate.getFullYear() +'/'+ ('0' + (startDate.getMonth()+1)).slice(-2) + '/' +('0' + startDate.getDate()).slice(-2) 
        let to_date = endDate.getFullYear() +'/'+ ('0' + (endDate.getMonth()+1)).slice(-2) + '/' +('0' + endDate.getDate()).slice(-2) 
        let filter={};
        filter['from_date'] = from_date;
        filter['to_date'] = to_date;
        filter['task_id'] = date_obj.task_id;
        this.fetchActivityLog(filter, 0);
        this.setState({apply_filter: filter});
      }

      clearFilter = () => {
        console.log("date_obj", this.state.apply_filter);
        this.fetchActivityLog(null, 1);
        this.setState({apply_filter: null});
      }
  exportToExcel = () => {
	let excel_arr = [];
	let fileName = 'activity_log';
	let table_data = this.processAcceptanceQueueData().data;
	//console.log("table_data,",table_data);
	//return;
	for(let d of table_data){
	  let elm ={
			'Module': d.module_id,
			'Activity Type': d.activity_type,
			'Client Name': d.client_name,
			'Changed By': d.changed_by,
			'Changed Date': d.changed_date,
	  }
	  excel_arr.push(elm);
	}

	let wb = {SheetNames:[], Sheets:{}};
	const ws1 = XLSX.utils.json_to_sheet(excel_arr); 
	wb.SheetNames.push("Activity_Log"); 
	wb.Sheets["Activity_Log"] = ws1;

	const format1 = "DD-MM-YYYY HH:mm:ss"
	let tm = moment().format(format1);

	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
	const data = new Blob([excelBuffer], {type: fileType});
	FileSaver.saveAs(data, fileName + '-' + tm + fileExtension);
	
  }
	render()
	{
  let headerText = "General Activity Log";
  let show = ""
  //show = this.state.m === 0 ? "(showing top 1000)": "("+ "showing "+ ( parseInt(this.state.m)) +")";
  if(this.state.module_id !== 'general') {
    headerText = typeof Source_Map[this.state.module_id] === "undefined" ? this.state.module_id + " Activity Log" : Source_Map[this.state.module_id] + " Activity Log "+ show;
  } 

  console.log("this.state.m", this.state.m)  
  console.log(this.state.n)  


		return (
            <div style={{display:"inline-block" , width:"84%", height:"900px" ,backgroundColor:"#ffffff"}}>
                <div style={{position:"absolute", padding: "17px" }}> 
                {/*{
                    (() => {
                        if(this.state.ddldate_val == 10000) {
                             return ( <div style={{position: "absolute", marginLeft:"300px", marginTop:"1px",  zIndex: "100"}}>
                              <div style={{display:"inline-block"}}>
                                          <label style={{paddingLeft:"10px", color: "#9e9e9e", fontWeight: "600"}}>Start Date : </label>
                                          <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.startDate} onChange={this.handleStartChange}/>
                              </div>
                              <div style={{display:"inline-block"}}>
                                          <label style={{paddingLeft:"10px", color: "#9e9e9e", fontWeight: "600"}}>End Date : </label>
                                          <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.endDate} onChange={this.handleEndChange}/>
                              </div>
                                          

                                          
                                      </div>);
                        }
                    })()
                }
                <div style={{float:"right"}}>
                    {
                      (() => {
                        if(this.state.module_id==='general') {
                          return(
                            <Dropdownlist id = "ddlmodule" onChange={this.changeModuleFilter} value={this.state.ddlmodule_val}>
                                  <option value="0">All</option>
                                  <option value="filechecker">File Checker</option>
                                  <option value="rcm">RCM</option>
                                  <option value="car">CAR</option>
                                  <option value="cmp">CMP</option>
                              </Dropdownlist> 
                            )
                        }
                      })()
                    }
                    <Dropdownlist id = "ddldate" onChange={this.changeDateFilter} value={this.state.ddldate_val}>
                        <option value="1000">Any date</option>
                        <option value="1">Last Month</option>
                        <option value="6">Last Six Months</option>
                        <option value="12">Last Year</option>
                        <option value="10000">Custom</option>
                    </Dropdownlist>     
                </div>*/}
                {
                  (() => {
                    if(Store.getStoreData('activitylog_data') === null) {
                      return <CSLLoader />
                    }

                  })()
                }   
                <div style={{clear: "both"}}></div>
                <div style={{boxSizing: "border-box", padding: "15px 10px 0px 10px"}}>
                    <AdvancedFilter applyFilter={this.applyFilter} clearFilter={this.clearFilter}/>
                </div> 
                {
                  (() => {
                    if(this.state.overloaded){
                      return <p>Too many rows returned. Please refine your filter.</p>;
                    }
                  })()
                }  
                {
                  (() => {
                    if(this.state.errorloaded) {
                      return <p> Please chnage your filter. </p>;
                    }
                  })()
                }      
                <CSLTable add={false} processData={this.processAcceptanceQueueData} headerText={headerText} tableRows={20} export={true} prefered_columns={this.state.prefered_columns} export_file_name={'Activity_Log'} offset={true} exportToExcel={this.exportToExcel} loadMore={this.loadMore} loadTop1000={this.loadTop1000} n={this.state.n}/>  
                </div>
                {
                    (() => {
                        if(this.state.modal_view !== null) {
                            return <div style={{position:'absolute', left:'10%', top:'50px', width:'100%', height:'1186px'}}><InactiveOverlay /><ActivityCompletedModal refreshParent={this.refreshState} modalData = {this.state.modal_data}/></div>;
                        }
                    })()
                }
            </div>         		  	 
		);
	}
}

export default ActivityLog;