import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import * as Style from './Common/StyledComponents';
import {FaAngleRight} from 'react-icons/fa';
import Dropzone from './Common/Dropzone.js';
import APICall from '../Common/APICall.js';

const Spacer = styled.div`
    height: 10px;
`;
const ApiInput = styled.input`
    height: 20px;
    width: 270px;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const ApiLabel = styled.label`
    color: #a0a0a0;
    font-weight: 600;
    margin-top: 10px;
`;
const ApiButton = styled.button`
    box-sizing: border-box;
    background-color: #234062;
    color: #AAB6C3;
    border: 1px solid #37ada7;
    width: 200px;
    font-size: 13px;
    height: 45px;
    border-radius: 3px;
    margin-left:36px;
    margin-top:21px;
    cursor:pointer;
`;

class ApiKeySecret extends React.Component
{
	state = { api_key:'', api_secret:'', msg_shown:false}

	componentDidMount()
    {
       
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            
        }
    }
    handleChange = (evt) => {
        this.setState({[evt.target.name]: evt.target.value});
    }
    regenerateApiKey = (evt) => {
        evt.preventDefault();
        var key = this.createRandomCode(16);
        var secret = this.createRandomCode(32);
        
        let postData = { command: "updateapicreds", api_key: key, api_secret: secret, email_address : this.props.emailAddress, url : window.location.href};
        let api = new APICall();
        api.command(postData, this.processApiKeySecret);
        //this.setState({msg_shown : true, api_key: key, api_secret: secret})
    }
    processApiKeySecret = (result) => { 
        console.log("okl", result)
       this.setState({msg_shown : true, api_key: result.api_key, api_secret: result.api_secret})
    }
    createRandomCode = (length) =>{
       var result           = '';
       var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
       var charactersLength = characters.length;
       for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }
       return result;
    }
	render()
	{
        
		return (
            <div style={{display:"inline-block" , width:"303px",height:"400px", padding: "10px", boxSizing:"border-box", backgroundColor:"#ffffff", marginLeft:"74px"}}>
                <div style={{position:"absolute"}}>
                    <span style={{fontWeight:"bold", color:"#a4a9b3"}}>API Key and Secret</span>
                    <Spacer/>
                    <Spacer/>
                    <ApiLabel>Api Key : </ApiLabel>
                    <Spacer/>
                    <ApiInput readOnly type="text" name="api_key" onChange={this.handleChange} value={this.state.api_key} />
                    <Spacer/>
                    <ApiLabel>Api Secret : </ApiLabel>
                    <Spacer/>
                    <ApiInput readOnly = "readonly" type="text" name="api_secret" onChange={this.handleChange} value={this.state.api_secret} />
                    <Spacer/>
                    <Spacer/>
                    {
                        (() => {
                            if(this.state.msg_shown){
                                return (
                                    <div>
                                           
                                        <ApiLabel style={{marginTop : "10px", color : "#ed0c22", fontSize:"10px"}}>Please note that the API Key and Secret will no </ApiLabel>
                                        <Spacer/>
                                        <ApiLabel style={{marginTop : "10px", color : "#ed0c22", fontSize:"10px"}}>longer be accessible after you leave this screen.</ApiLabel>
                                        <Spacer/>
                                        <ApiLabel style={{marginTop : "10px", color : "#ed0c22", fontSize:"10px"}}>So you are advised to copy these to a secure location </ApiLabel>
                                        <Spacer/>
                                        <ApiLabel style={{marginTop : "10px", color : "#ed0c22", fontSize:"10px"}}>locally. If you lose these, the only recourse will </ApiLabel>
                                        <Spacer/>
                                        <ApiLabel style={{marginTop : "10px", color : "#ed0c22", fontSize:"10px"}}>be to come to this page and regenerate a new pair.</ApiLabel>
                                    </div>
                                    
                                    );
                            }                         
                         })()
                     }
                    
                    <Spacer/>
                    <Spacer/>
                    <ApiButton onClick={this.regenerateApiKey}>Regenerate Key</ApiButton>
                </div>
            </div>                  
		);
	}
}

export default ApiKeySecret;