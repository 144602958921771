import React from 'react';
import styled from 'styled-components';
import APICall from '../../Common/APICall.js';
import AlertBox from '../Common/AlertBox';

const ConfigBtn = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    font-size: 13px;
    border-radius: 4px;
    padding: 5px 20px;
    cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 20px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 20px;
`;
const RadioBlock = styled.div`
	margin-top: 10px;
`;
const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const SubHeading = styled.div`
	height: auto;
    width: 100%;
    padding-top: 30px;
    font-weight: 600;
    color: #282828;
`;
const DropdownOptions = styled.textarea`
	border: 1px solid rgb(255, 255, 255);
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(196 196 196) 0px 0px 4px;
    resize: none;
    padding: 5px;
    width: 98%;
    font-family: Montserrat, sans-serif;
    margin-top: 7px;
`;
const ModalTextArea = styled.textarea`
    height: 150px;
    width: calc(100% - 13px);
    border: 1px solid #ffffff;
    border-radius: 3px;
    background-color:#ffffff
    box-shadow: 0 0 4px #c4c4c4;
    resize: none;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;

const ModalInput = styled.input`
    height: 40px;
    width: calc(100% - 13px);
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const ModalNameInputDiv = styled.div`
    height: auto;
    width: 100%;
    padding-top:15px;
    color: #282828;
    font-family: 'Montserrat', sans-serif;
`;
const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`;
const CancelBtn = styled.button`
	padding: 10px 20px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 2px;
	color: #333333;
	cursor: pointer;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 15px;
	padding-bottom: 12px;
`;


class EmailFooter extends React.Component
{
    state = { 
        ready: false,
        show_email_footer: false,
        logo_url:"",
        phone:"",
        wbsite:"",
        linkedin:"",
        address:"",
        description:"",
        id:"0",
        alert_param: null
        
    }; 

    constructor(props) {
        super(props);
    } 
    
    componentDidMount() {
        this.callfooter()
        
    } 
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.callfooter()
        }
    }
    
    callfooter = () => {
        let postData = { command: "get_footer_data" };
        let api = new APICall();
        api.command(postData, this.processemailFooter)
    }
    
    processemailFooter = (result) => {
        if(result) {
            this.setState({show_email_footer: result.show_email_footer,logo_url: result.logo_url, phone: result.phone, email: result.email, wbsite: result.wbsite, linkedin: result.linkedin, address: result.address, description: result.description, id: result._id })
        }
        console.log("Processed footer",result)
        this.setState({ready:true})
        
    }
    
	switchRadio = (key) => (event) => {
		event.preventDefault();
		this.setState({show_email_footer :!this.state.show_email_footer})
	}
    
    handleChange = (event) => {
        this.setState({ [event.target.name] : event.target.value })
    }
    
    footer_save = () => {
        let footer_data = this.state;
        let postData = {command: "save_footer_data",footer_data};
        const api = new APICall();
        api.command(postData, this.processFooter);

    }
    
    processFooter = (result) => {
        if (result.error_code === 0) {
            let alert_param = { title: 'Alert', message: 'Email footer details have been saved.', ok_text: 'OK', confirm: false, alertHandler: this.successAlertHandler, stack: result }
            this.setState({ alert_param: alert_param });
        } else {
            let alert_param = { title: 'Error', message: result.error_msg, ok_text: 'OK', confirm: false, alertHandler: this.alertHandler, stack: { id: 0 } }
            this.setState({ alert_param: alert_param });
        }
    }
    
    successAlertHandler = (result, stack) => {
        this.setState({ alert_param: null})
    }

    render() {
        if (this.state.ready === false) {
            return <div>Loading ....</div>
        }
      return (
          
        <GeneralContainer>
            <GeneralInnerContainer>
                <GeneralLabel>Enable Email Footer</GeneralLabel>
                {
					(() => {
						if (this.state.show_email_footer) {
							return (
								<RadioBlock>
									<SelectedRadio>Yes</SelectedRadio>
									<UnselectedRadio onClick={this.switchRadio('show_email_footer')}>No</UnselectedRadio>
								</RadioBlock>
							);
						} else {
							return (
								<RadioBlock>
									<UnselectedRadio onClick={this.switchRadio('show_email_footer')}>Yes</UnselectedRadio>
									<SelectedRadio>No</SelectedRadio>
								</RadioBlock>
							);
						}
					})()
				}
                
            </GeneralInnerContainer>
            
            {
				(() => {
					if (this.state.show_email_footer) {
						return (
							<GeneralInnerContainer>
								<ModalNameInputDiv>
									<SubHeading>Logo url</SubHeading>
								</ModalNameInputDiv>	
								<ModalNameInputDiv>
	                                <ModalInput style={{width: '98%'}} name="logo_url" value={this.state.logo_url} onChange={this.handleChange} placeholder="logo url"/>
	                            </ModalNameInputDiv>
	                            <ModalNameInputDiv>
                                	<SubHeading>Contact</SubHeading>
                                </ModalNameInputDiv>	
                                <ModalNameInputDiv>
	                                <ModalInput style={{width: '98%'}} type= "text" name="phone" value={this.state.phone} onChange={this.handleChange} placeholder="Phone number"/>
	                            </ModalNameInputDiv>
	                            {/*<ModalNameInputDiv>
                                	<SubHeading>Email send from</SubHeading>
                                </ModalNameInputDiv>
                                <ModalNameInputDiv>
	                                <ModalInput style={{width: '98%'}} type="email" name="email" value={this.state.email} onChange={this.handleChange} placeholder="From e-mail"/>
	                            </ModalNameInputDiv> */}
	                            <ModalNameInputDiv>
                                	<SubHeading>Company link</SubHeading>
                                </ModalNameInputDiv>	
                                <ModalNameInputDiv>
	                                <ModalInput style={{width: '98%'}} name="wbsite" value={this.state.wbsite} onChange={this.handleChange} placeholder="url"/>
                                </ModalNameInputDiv>
                                <ModalNameInputDiv>
                                	<SubHeading>Linkedin url</SubHeading>
                                </ModalNameInputDiv>
                                <ModalNameInputDiv>
	                                <ModalInput style={{width: '98%'}} name="linkedin" value={this.state.linkedin} onChange={this.handleChange} placeholder="url"/>
                                </ModalNameInputDiv>
                                <ModalNameInputDiv>
                                	<SubHeading>Company Address</SubHeading>
                                </ModalNameInputDiv>	
                                <ModalNameInputDiv>
                                    <ModalTextArea rows="7" name="address" value={this.state.address} onChange={this.handleChange} placeholder="Address" />
	                            </ModalNameInputDiv>
	                            <ModalNameInputDiv>
                                	<SubHeading>Content</SubHeading>
                                </ModalNameInputDiv>
                                <ModalNameInputDiv>
									<ModalTextArea rows="7" name="description" value={this.state.description} onChange={this.handleChange} placeholder="Description" />
								</ModalNameInputDiv>
							</GeneralInnerContainer>
							);
					}
				})()
			}
			<FooterButtonsContainer>
			    <SaveBtn onClick={() => this.footer_save()}>Save</SaveBtn>
			</FooterButtonsContainer>
			{
                (() => {
                    if (this.state.alert_param !== null) {
                        return <AlertBox alertParam={this.state.alert_param} />
                    }
                })()
            }
        </GeneralContainer>
      )
    }
}

export default EmailFooter;