import React from 'react'
import styled from 'styled-components'
import {FaPlus} from 'react-icons/fa'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Pagination from "./Pagination";

import "./table_styles.css";

const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 95px;
    font-size: 13px;
    height: 25px;
    border-radius: 0px;
    margin-right:1px;
`;
const HeaderText = styled.div`
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #c0c0c0;
    font-weight: 600;
    letter-spacing: 1px;
`;
const TableHeaderIcon = styled.div`
    float: right;
    margin-right: 20px;
    margin-top: 15px;
    color: #c0c0c0;
`;

const TextPara = styled.div`
    padding: 15px 15px 15px 15px;
    color: #c0c0c0;
`
const TableContainer = styled.div`
    background-color: #ffffff;
`
const ExportButton = styled.button`
    box-sizing: border-box;
    background-color: #213E61;
    color: #F2F2F2;
    border: 1px solid #213E61;
    width: 125px;
    font-size: 14px;
    height: 32px;
    border-radius: 5px;
    margin: 12px;
    cursor: pointer;
`;

class CSLTable extends React.Component
{
    constructor(props) {
        super(props);
    }

    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id
        // console.log('id',id);
        return row[id] !== undefined ? String(row[id]).includes(filter.value) : true
    }

    exportToCSV = () => (event) => {
        event.preventDefault();
        let fileName = "export";
        if('export_file_name' in this.props) {
            fileName = this.props.export_file_name;
        }
        let tableData = this.props.processData();
        let headerCSV = this.props.prefered_columns;
        // let headerCSV = Store.getStoreData('prefered_columns');
        console.log("headerCSV",headerCSV)
        console.log("tableData",tableData)
        let data= tableData.data;
        let columns= tableData.columns;
        let csv_data_arr = [];
        
        csv_data_arr.unshift(headerCSV);
        for(let k = 0; k < data.length; k++) {
            let cont = {};

            Object.entries(headerCSV).forEach(([key, value]) => {
                console.log("data[k][key]",data[k][key])
                    cont[key] = data[k][key].replace(/,/g, ' ').replace(/--/g, ' ').replace(/[\r\n]+/g," ").replace(/&nbsp;/g, '').replace(/#/g, '');
            })            
            csv_data_arr.push(cont);
        }
        console.log("csv_data_arr",csv_data_arr) 
        var csv_data_final_arr = [];
        for(let j = 0; j<  csv_data_arr.length; j++){
          var container = [];
          container = Object.values(csv_data_arr[j])
          if(container.length > 0); 
          csv_data_final_arr.push(container)
        }
        console.log("csv_data_final_arr",csv_data_final_arr)
        let csvContent = "data:text/csv;charset=utf-8," + csv_data_final_arr.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName + ".csv");
        document.body.appendChild(link); 
        link.click();
    
    }


	render() {
		// console.log('this.props', this.props);
        let tableData = this.props.processData();
        let row_count = tableData.row_count === 0 ?  3 : 5;
        // console.log(row_count);
		// console.log('tableData', tableData);
		return (
			<TableContainer>
			    <HeaderText>{this.props.headerText}</HeaderText>                    
                {
                    (() => {
                        if (this.props.add) {
                            return (<TableHeaderIcon><FaPlus style={{cursor: "pointer"}} onClick={this.showAddAskModal} title={this.props.addTitle} /></TableHeaderIcon>);
                        }
                    })()
                }                    
                    <div style={{marginTop: '5px', width:'100%'}}>
	                    <ReactTable
	                    	PaginationComponent={Pagination}
						    data={tableData.data}
						    columns={tableData.columns}
						    defaultPageSize={row_count}
                            filterable={true}
                            defaultFilterMethod={(filter, row, column) => {
        const id = filter.pivotId || filter.id
        console.log('id ===>',id);
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
    }}

						/>
					</div>
                    {
                        (() => {
                            if ('export' in this.props && this.props.export === true && tableData.data.length > 0) {
                                return (<ExportButton  onClick={this.exportToCSV()}>Export</ExportButton>);                                                        
                            }
                        })()
                    }
                    {
                        /*
                        {
                            (() => {
                                if('offset' in this.props && this.props.offset === true && tableData.data.length > 1000) {
                                    return (<ExportButton  onClick={this.exportToCSV()}>Load more ...</ExportButton>);
                                }

                            })()
                        }
                        */
                    } 

			</TableContainer>
			);
	}
}

export default CSLTable;