import React from 'react';
import styled from 'styled-components';
import Store from './Common/Store.js';
import APICall from './Common/APICall.js';
import Utils from './Common/Utils.js';
import Index from './Components/Index';
import NotificationIndex from './Components/NotificationIndex';
import AuditlogsIndex from './Components/AuditlogsIndex'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import EmailLog_CSL from './Components/Common/EmailLog_CSL.js';
import ComDecom from './Common/ComDecom.js';
// import SiteLayout from './Components/SiteLayout/SiteLayout.js';
// import ErrorBoundary from './Components/SiteLayout/ErrorBoundary.js';
import authService from 'mcc-front-aux/dist/authService'

/*global localStorage*/

const moment = require('moment');
const last_action_time = moment().unix()


class AppContent extends React.Component
{

	constructor(props) {
		super(props);
		Utils.setEnv('mccgeneral');
		Store.updateStore('module_name', 'mccgeneral');
    }
	render()
	{
        return(
            <Router>
                <Switch>
                    {/*<Route path="/" exact>*/}
                    {/*    <Index />*/}
                    {/*</Route>*/}
                    {/*<Route path="/notifications" exact>*/}
                    {/*    <NotificationIndex />*/}
                    {/*</Route>*/}
                    {/*<Route path="/mccgeneral" exact>*/}
                    {/*    /!*<EmailLog_CSL />*!/*/}
                    {/*    <Index />*/}
                    {/*</Route>*/}
                     <Route path="/auditlogs/:moduleID" component={AuditlogsIndex} />
                </Switch>
            </Router>
        );
	}
}

class App extends React.Component {
  state = { ready: false}

  setComponentState = () => {
    console.log('this.props', this.props)
    let is_debug = false
    let api_url = process.env.REACT_APP_API_URL;
    Store.updateStore('is_debug', is_debug)
    Store.updateStore('api_url', api_url)
    // this.setState({ready: true})
      authService.refresh().then(() => {
          this.setState({ready: true})
      })
  }


  componentDidMount = () => {
    this.setComponentState()
  }

  componentDidUpdate = (prevprops) => {
        if(this.props === prevprops) return
        this.setComponentState()
    }

  render() {
    if(!this.state.ready) {
        return <div>Loading...</div>
    }
    // let appContent = <AppContent />
    //
    // return <ErrorBoundary><SiteLayout AppContent={appContent} /></ErrorBoundary>
      return <AppContent />
  }
}

export default App;
