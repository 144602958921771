import Store from './Store.js';

const roleScore = {
    "SMF" : 6,
    "SMP" : 5,
    "SMO" : 4,
    "CRR" : 3,
    "COC" : 2,
    "OTH" : 1,
    "Mis" : 0
};
const roleColor=["#e2e2e2", "#d88480", "#a6d9a1", "#efac4e", "#49668a", "#49668a", "#49668a" ];

class Utils {
    // constructor() {
    // }
    genKey = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    setEnv(module_name) {
        let api_url = process.env.REACT_APP_API_URL;

        Store.updateStore('module_name',module_name);
        Store.updateStore('api_url',api_url);
        Store.updateStore('is_admin',false);
        Store.updateStore('is_debug',false);
    }

    getMaxRoleColor(roles) {
        let maxRole = 0;
        for(var r in roles) {
            let key = r.substring(0,3);
            if(maxRole < roleScore[key]) maxRole = roleScore[key];
        }

        return roleColor[maxRole];
    }

    getRoleColor(role) {
        let key = role.substring(0,3);
        let ret = '#FFFFFF';
        if(key === 'CRR') ret = '#000000';
        return ret;
    }
    getRoleBGColor(role) {
        let key = role.substring(0,3);
        return roleColor[roleScore[key]];
    }
    isBase64(str) {
        if (str ==='' || str.trim() ===''){ return false; }
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }
}

export default new Utils();
