import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import * as Style from './Common/StyledComponents';
import {FaAngleRight} from 'react-icons/fa';
import Dropzone from './Common/Dropzone.js';
import APICall from '../Common/APICall.js';
const Spacer = styled.div`
    height: 10px;
`;



class ProfilePic extends React.Component
{
	state = { 
				 binFile: null,
                 ProfilePicture: null,
                 show_warning_size: false,
                 show_warning_ext:false
			}

	componentDidMount()
    {
        this.setState({ProfilePicture: this.props.ProfilePicture, show_warning_size: false, show_warning_ext:false});
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({ProfilePicture: this.props.ProfilePicture, show_warning_size: false, show_warning_ext:false});
        }
    }

    onFilesAdded = (file) => {     
        this.props.onFilesAdded(file)
    }
    removePhoto = () => {
       var confirm = window.confirm('Your profile picture will be removed. Are you sure?');
       if(confirm == true){
            var file = [{name:'',  bin_file: ''}];       
            this.props.onFilesAdded(file)
      }
    }
    processIndexData = (result) => {
        //console.log("result",result)
    }
    onFilesAddedSizeExceed = () =>{
        this.setState({show_warning_size: true})
    }
    onFilesAddedExtNotMatched = () =>{
        this.setState({show_warning_ext:true})
    }

	render()
	{
        // console.log("this.state.ProfilePicture", this.state.ProfilePicture)
        if (this.state.ProfilePicture === null) {
            return (<div>Loading...</div>)
        }
         
        return (
            <div style={{display:"inline-block" , width:"322px", height:"500px", padding: "10px", boxSizing:"border-box", backgroundColor:"#ffffff", marginLeft:"76px", position:"absolute"}}>
                <span style={{fontWeight:"bold"}}> Profile Picture</span>
                {
                    (() =>{
                        if(this.state.ProfilePicture !=''){
                            return <span style={{float:"right", color:"#a4a9b3", fontSize:"20px", cursor:"pointer"}} onClick={this.removePhoto}>x</span>
                        }
                    })()
                }
                <div style={{fontWeight:"700",fontSize: "10px", padding: "2px", width: "300px", marginTop: "4px"}}>For the best quality profile picture, please upload a square picture</div>
                {
                    (() =>{
                        if(this.state.show_warning_size == true){
                            return <div><span style={{fontWeight:"bold", color:"#ff3333",fontSize: "12px"}}> Maximum upload file size: 500 KB.</span></div>
                        }
                    })()
                }
                <Spacer/> 
                {
                    (() =>{
                        if(this.state.show_warning_ext == true){
                            return <div><span style={{fontWeight:"bold", color:"#ff3333",fontSize: "12px"}}> Uploaded file is not a valid image. Only JPG, PNG and GIF files are allowed</span></div>
                        }
                    })()
                }
                <Spacer/> 
                <div style={{padding: "10px 15px 20px"}}>                 
                <Dropzone onFilesAdded={this.onFilesAdded} ProfilePicture = {this.state.ProfilePicture} onFilesAddedSizeExceed = {this.onFilesAddedSizeExceed} onFilesAddedExtNotMatched ={this.onFilesAddedExtNotMatched}/>
                </div>
                <Spacer/>
                <Spacer/>
            </div>                  
		);
	}
}

export default ProfilePic;