import React from 'react';
import styled from 'styled-components';

const Pill = styled.div`
	padding: 1px;
	box-sizing: border-box;
	background-color: #FFFFFF;
	margin-left: 0px;
	border: 1px solid #E9EBEE;
	border-radius: 12px;
	position: relative;
	height: 24px;
	width: 40px;
	cursor: pointer;
`;

const InnerPill = styled.div`
	box-sizing: border-box;
	margin-left: 0px;
	margin-top: 0px;
	border: 1px solid #143151;
	border-radius: 10px;
	position: relative;
	height: 20px;
	width: 20px;
	background-color: #143151;
`;

const InnerPillInactive = styled.div`
	box-sizing: border-box;
	margin-left: 0px;
	margin-top: 0px;
	border: 1px solid #E9EBEE;
	border-radius: 10px;
	position: relative;
	height: 20px;
	width: 20px;
	background-color: #E9EBEE;
`;



class CSLCheckBox extends React.Component {

	handleChange = (mail) => (event) => {
		event.preventDefault();
		let thismail = JSON.parse(JSON.stringify(mail));
		thismail.active = thismail.active === true ? false : true;
		// console.log("thismail:", thismail);
		this.props.toogleCheckBox(thismail);
	}

	render()
	{
		return (
			<div>
				<Pill onClick={this.handleChange(this.props.mail)}>
					{
						(() => {
							if(this.props.mail.active) {
								return <InnerPill style={{float: "right"}} />
							} else {
								return <InnerPillInactive style={{float: "left"}} />
							}
						})()
					}
					<div style={{clear: "both"}}></div>
				</Pill>
			</div>
		);
	}
}

export default CSLCheckBox