import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import * as Style from './Common/StyledComponents';
import {FaAngleRight} from 'react-icons/fa';

const Spacer = styled.div`
    height: 10px;
`;
const PasswordInput = styled.input`
    height: 20px;
    width: 387px;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const PasswordLabel = styled.label`
    color: #a0a0a0;
    font-weight: 600;
    margin-top: 10px;
`;
const ValidationMsg = styled.div`
    margin-top: 5px;
    color: #d60606;
    font-weight: 400;
`;
const Right = styled.label`
    margin-left: 10px;
    color: green;
`
const Wrong = styled.label`
    margin-left: 10px;
    color: red;
`
class SecurityInfo extends React.Component
{
	state = { 
				cur_view: null, 			
				cur_view: null,     
		    screen:{profile:1,password:0,preferences:0},
        current_password : '',
        new_password : '',
        confirm_password : '',
        is_password_match : 0
			}
  componentDidMount = () => {
   
    }
	constructor(props) {
		super(props);
	}

  handleChange = (evt) => {
        var is_password_match = 0;
        if([evt.target.name] == 'confirm_password' || [evt.target.name] == 'new_password'){
           
            if(this.state.new_password == evt.target.value){
                is_password_match = 1
            }else{ 
                is_password_match = 2    
            } 
        }
        this.props.refreshParentCallback('password',evt.target.name,evt.target.value , 1, is_password_match) 
        this.setState({[evt.target.name]: evt.target.value, is_password_match :is_password_match});
    }
	render()
	{
		return (
		  <div style={{display:"inline-block" , width:"400px", height:"400px", marginLeft:"4px"}}>
            <div style={{position:"absolute"}}>
                <div style={{padding: "19px" ,backgroundColor:"#ffffff"}}>
                       <PasswordLabel>Current Password</PasswordLabel>
                       <Spacer/>
                       <PasswordInput type="password" name="current_password" onChange={this.handleChange} value={this.state.current_password} />
                       <Spacer/>
                       <PasswordLabel>New Password</PasswordLabel>
                       <Spacer/>
                       <PasswordInput type="password" name="new_password" onChange={this.handleChange} value={this.state.new_password} />
                       <Spacer/>
                       <PasswordLabel>Confirm Password</PasswordLabel>
                       <Spacer/>
                       <PasswordInput type="password" name="confirm_password" onChange={this.handleChange} value={this.state.confirm_password} />
                       {
                         (() => {
                            if(this.state.is_password_match == 1){
                                return <Right>&#10004;</Right>;
                            }else if (this.state.is_password_match == 2){                          
                                return<Wrong>&#10006;</Wrong>;
                            }
                             
                         })()
                     }
                       <Spacer/>                       
                       <Spacer/>
                       <Spacer/>
                </div>
            </div>
        </div>
            
		);
	}
}

export default SecurityInfo;