import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import * as Style from './Common/StyledComponents';
import { BiRadioCircleMarked,  BiRadioCircle} from "react-icons/bi";

const DropDown = styled.select`
	display: block;
    box-sizing: border-box;
    height: 38px;
    width: 60%;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(255, 255, 255);
    border-radius: 3px;
    box-shadow: rgb(196, 196, 196) 0px 0px 4px;
    font-family: Montserrat, sans-serif;
`;
const Radio = styled.div`
	float: left;
	font-size: 23px;
	color: #143151;
	cursor: pointer;
`;
const SaveButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    width: 107px;
    font-size: 13px;
    height: 35px;
    border-radius: 3px;
    cursor: pointer;
`;
const CancelButton = styled.button`
    box-sizing: border-box;
    background-color: #ffffff;
    color: #90949e;
    border: 1px solid #ced1d6;
    width: 75px;
    font-size: 13px;
    height: 35px;
    border-radius: 3px;
    margin-right:10px;
    cursor: pointer;
`;

class GeneralInfo extends React.Component
{

	state = {languages: [], user_langs: {}, selct_lang: 'en', is_lan: false};

	selectAppLanding = (obj_name) => (event) => {
		event.preventDefault();
		let user_data = JSON.parse(JSON.stringify(this.props.user_data));
		user_data.app_landings.forEach((item) => {
			if (item.name === obj_name) {
				item.selected = true;
			} else {
				item.selected = false;
			}
		})
		this.props.selectAppLanding(user_data);
	}

    componentSetState = () => {
        let languages = this.props.languages;
        let user_langs = this.props.user_langs;
        let is_lan = this.props.is_lan;
        let contact_id = this.props.contact_id;
        // console.log("selct_lang", user_langs, is_lan, contact_id, user_langs[contact_id.toString()].lang)
        let selct_lang = this.state.selct_lang;
        if(contact_id > 0 && is_lan){
           selct_lang =  user_langs[contact_id.toString()] === undefined ? 'en' : user_langs[contact_id.toString()].lang;
        }
        // alert(is_lan)

        // console.log("selct_lang", selct_lang, user_langs, this.props.contact_id)

        this.setState({languages: languages, user_langs: user_langs, selct_lang: selct_lang, is_lan: is_lan})
    }

	componentDidMount = () => {
        this.componentSetState()
	}

    componentDidUpdate = (prev_props) => {
        if(this.props !== prev_props) {
            this.componentSetState()
        }
    }

	selectLandingModule = (event) => {
		event.preventDefault();
		let user_data = JSON.parse(JSON.stringify(this.props.user_data));
		user_data.app_landings.forEach((item) => {
			if (item.name === "landing_module") {
				item.module_name = event.target.value;
			}
		})
		this.props.selectAppLanding(user_data);
	}

	selectModuleAppLanding = (module_name, url_name) => (event) => {
		event.preventDefault();
		let user_data = JSON.parse(JSON.stringify(this.props.user_data));
		user_data.modules.forEach((item) => {
			if (item.module === module_name) {
				item.urls.forEach((url) => {
					if (url.name === url_name) {
						url.selected = true;
					} else {
						url.selected = false;
					}
				})
			}
		})
		this.props.selectAppLanding(user_data);
	}

	saveUserData = (event) => {
		event.preventDefault();
		this.props.saveUserData(this.state.selct_lang, this.state.is_lan);
	}

	handleChange = (evt) => {
	    this.setState({[evt.target.name]: evt.target.value});
	}

	render()
	{
		return (
			<div style={{float: "left", marginLeft:"2px", width: "55%"}}>
				<div style={{fontWeight: "600", padding: "19px", backgroundColor:"#ffffff"}}>General</div>
				<div style={{width: "100%", height: "1px", backgroundColor: "#dcd7d7"}}></div>
				<div style={{padding: "30px 19px", boxSizing: "border-box", backgroundColor:"#ffffff"}}>
					
					{
					    (() => {
					        if(this.state.is_lan) {
					            return(<div>
					                <div style={{fontWeight: "600"}}>Language Preferances</div>
					                <div style={{paddingTop: "10px"}}>
					                <DropDown onChange={this.handleChange} name="selct_lang" value={this.state.selct_lang} >
					                    {
					                        (() => {
					                            if(this.state.languages.length !== 0) {
					                                let lags = [];
					                                let i=0
					                                for(let l of this.state.languages){
					                                    lags.push(<option key={l.language_id} value={l.language_id}>{l.language_name}</option>);
					                                    i++
					                                }      
					                                return lags;
					                            }
					                        })()
					                    }
					                </DropDown>
					                </div>
					            </div>)
					        }
					    })()
					}  

	


					<div style={{fontWeight: "600", marginTop: "20px"}}>Your homepage displays</div>
					<div style={{paddingTop: "6px"}}>Choose where you land when you log in. This can be your services screen, the last screen you visited, or a particulat MCC Module</div>
					<div style={{paddingTop: "6px"}}>
						{
							this.props.user_data.app_landings.map((item) => {
								return (
									<div style={{float: "left", paddingRight: "20px"}} key={item.name}>
										{
											(() => {
												if (item.selected === true) {
													return (<Radio onClick={this.selectAppLanding(item.name)}><BiRadioCircleMarked /></Radio>)
												} else {
													return (<Radio onClick={this.selectAppLanding(item.name)}><BiRadioCircle /></Radio>)
												}
											})()
										}
										<div style={{float: "left", fontWeight: "600", paddingTop: "3px", paddingLeft: "4px"}}>{item.nickname}</div>
										<div style={{clear: "both"}}></div>
									</div>
								);
							})
						}
						<div style={{clear: "both"}}></div>
						{
							(() => {
								const landing_module = this.props.user_data.app_landings.find(item => item.name === "landing_module");
								if (landing_module.selected === true) {
									return (
										<div style={{paddingTop: "15px"}}>
											<div style={{fontWeight: "600"}}>Select the MCC Module you would like to go straight to</div>
											<div style={{paddingTop: "10px"}}>
												<DropDown value={landing_module.module_name} onChange={this.selectLandingModule}>
												{
													this.props.user_data.modules.map((item) => {
														return (<option value={item.module} key={item.module}>{item.nickname}</option>)
													})
												}
												</DropDown>
											</div>
										</div>
									);
								}
							})()
						}
						<div style={{fontWeight: "600", marginTop: "20px"}}>Module Landing Page</div>
						<div style={{marginTop: "3px"}}>
							When you switch between modules, choose to always go to the Module's Home Page, the module's Register table, or back to the page you last visited.
						</div>
						<div style={{paddingTop: "15px"}}>
						{
							this.props.user_data.modules.map((item) => {
								return (
									<div style={{paddingBottom: "8px"}} key={item.CSLMenuID}>
										<div style={{fontWeight: "600"}}>{item.nickname} Landing Page</div>
										<div style={{marginTop: "6px"}}>
										{
											item.urls.map((url, index) => {
												return (
													<div style={{float: "left", paddingRight: "20px"}} key={index}>
														{
															(() => {
																if (url.selected === true) {
																	return (<Radio onClick={this.selectModuleAppLanding(item.module, url.name)}><BiRadioCircleMarked /></Radio>)
																} else {
																	return (<Radio onClick={this.selectModuleAppLanding(item.module, url.name)}><BiRadioCircle /></Radio>)
																}
															})()
														}
														<div style={{float: "left", fontWeight: "100", paddingTop: "3px", paddingLeft: "4px"}}>{url.nickname}</div>
														<div style={{clear: "both"}}></div>
													</div>
												);
											}, item)
										}
										<div style={{clear: "both"}}></div>
										</div>
									</div>
								);
							})
						}
						</div>
					</div>
				</div>
				<div style={{float: "right", marginTop: "20px"}}>
					<CancelButton>Cancel</CancelButton>                           
                    <SaveButton onClick={this.saveUserData}>Save</SaveButton>
				</div>
				<div style={{clear: "right"}}></div>
			</div>
		);
	}
}

export default GeneralInfo;