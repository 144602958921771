import React from 'react';
import styled from 'styled-components';
import {IoIosArrowForward, IoIosLock, IoIosArrowDown, IoIosArrowUp} from 'react-icons/io';
import EmailFooter from '../Components/CSL/EmailFooter';
import JwgFeeds from '../Components/CSL/JwgFeeds';
import APICall from '../Common/APICall.js';



const ReviewBodyContainer = styled.div`
    padding-top: 25px;
    width: 100%;
`;
const ReviewLeftContainer = styled.div`
    float: left;
    padding-right: 20px;
    width: 20%;
    box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
    float: right;
    padding-left: 20px;
    width: 80%;
    box-sizing: border-box;
`;
const ReviewTab = styled.div`
    background-color: #ffffff;
    color: #222222;
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

class CSLIndex extends React.Component
{
    state = {active_section_id: "email_footer", returnSectionToggle: true, selected_module: 'config', ready: false, manu_options:{} }

    constructor(props) {
		super(props);
    }

    componentDidMount() {
      this.callMenuoptions()
    }
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.callMenuoptions()
        }
    }

    callMenuoptions = () => {
        let postData = { command: "get_csl_menu" };
        let api = new APICall();
        api.command(postData, this.processCslMenuoptions)
    }

    processCslMenuoptions = (result) => {
      console.log("result123", result)
      this.setState({ready: true, manu_options: result.config})
    }



    returnSection = (section) => (event) => {
        event.preventDefault();
        this.setState({active_section_id: section});
    }

    returnSectionToggle = (module)=> (event) => {
        event.preventDefault();
        this.setState({returnSectionToggle :!this.state.returnSectionToggle, selected_module: module})

    }


    render() {
        if(!this.state.ready) {
                return <div>Loading...</div>
            }
        return(
          <div style={{padding: "10px 10px 20px 10px"}}>
            <ReviewBodyContainer>
				      <ReviewLeftContainer style={{position: "sticky", top: "2px"}}>
              <ReviewTab onClick={this.returnSectionToggle('config')} style={{marginTop: "2px"}}>
                  {
                      (() => {
                          if (this.state.returnSectionToggle) {
                              return (<div style={{float: "left", fontWeight: "600"}}>Configuration</div>);
                          } else {
                              return (<div style={{float: "left"}}>Configuration</div>);
                          }
                      })()
                  }
                  <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}>{this.state.returnSectionToggle? <IoIosArrowDown /> : <IoIosArrowUp />} </div>
                  <div style={{clear: "both"}}></div>
              </ReviewTab>
              {this.state.returnSectionToggle &&<div>

                {
                  (() => {
                    if(this.state.manu_options['show_email'] === "1") {
                      return(
                        <ReviewTab onClick={this.returnSection("email_footer")} style={{marginTop: "1px"}}>
                          {
                            (() => {
                              if (this.state.active_section_id === "email_footer") {
                                return(<div style={{paddingLeft: 20, float: "left", fontWeight: "600"}}>E-mail Footer</div> )
                              }else{
                                return(<div style={{paddingLeft: 20, float: "left"}}>E-mail Footer</div> )
                              }
                            })()
                          }
                          <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                          <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        )
                    }

                  })()
                }
                {
                  (() => {
                    if(this.state.manu_options['show_jwg'] === "1") {
                      return(
                        <ReviewTab onClick={this.returnSection("jwg_feed")} style={{marginTop: "1px"}}>
                          {
                            (() => {
                              if (this.state.active_section_id === "jwg_feed") {
                                return(<div style={{paddingLeft: 20, float: "left", fontWeight: "600"}}>JWG Feeds</div> )
                              }else{
                                return(<div style={{paddingLeft: 20, float: "left"}}>JWG Feeds</div> )
                              }
                            })()
                          }
                          <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                          <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        )
                    }

                  })()
                }
                </div>
              }
              </ReviewLeftContainer>
              <ReviewRightContainer>
              {
                  (() => {
                    if (this.state.active_section_id === "email_footer" && this.state.manu_options['show_email'] === "1") {
                        return (<EmailFooter />);
                    }
                    if (this.state.active_section_id === "jwg_feed" && this.state.manu_options['show_jwg'] === "1") {
                        return (<JwgFeeds />);
                    }
                  })()
                }
              </ReviewRightContainer>
              <div style={{clear:"both"}}></div>
            </ReviewBodyContainer>
          </div>)
    }
}

export default CSLIndex;
