import React from 'react';
import styled from 'styled-components';
import Store from '../Common/Store.js';
import * as Style from './Common/StyledComponents';
import {FaAngleRight} from 'react-icons/fa';
import APICall from '../Common/APICall.js';
import {FaTimes} from 'react-icons/fa';


const MRModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    height: auto;
    position: absolute;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
    padding: 20px;
    width: 54%;
    left: 14%;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 20px 20px 10px 20px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #a0a0a0;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #a0a0a0;
    cursor: pointer;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
`;
const MRModalBodyInner = styled.div`
    border : 1px solid;
    border-color: #C2C2C2
    border-radius: 4px;
    padding: 15px;
    background-color: #ffffff;
`;
const MRModalLabel = styled.label`
    color: #a0a0a0;
    font-weight: 300;
    margin-top: 10px;
    padding-left: 6px;
`;
const MRModalInput = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    color: #979797;
`;
const MRModalDivider = styled.div`
    background-color: #A0A0A0;
    height:3px;
    margin-top: 20px;
`;
const Spacer = styled.div`
  height: 2px;
`;
const MRModalLabelTitle = styled.label`
    color: #a0a0a0;
    font-weight: bold;
    margin-top: 10px;
`;
const CLButton = styled.button`
    box-sizing: border-box;
    background-color: #ffffff;
    color: #A0A0A0;
    border: 1px solid #A0A0A0;
    width: 100px;
    font-size: 13px;
    height: 31px;
    border-radius: 2px;
    margin-top: 39px;
    float: right;
`;

class ActivityCompletedModal extends React.Component
{
	state = { 
				modalData : ''
			}

	componentDidMount()
    {
        this.setState({summaryData : this.props.modalData})
    }

    closeModal = (event)=>{
        event.preventDefault();
        this.props.refreshParent(null)
    }

    getTaskCompleteNote = (m, object_data) => {
        let completed_note = ('completed_note' in object_data) ? object_data.completed_note : "";
        switch(m) {
            case "docmgmt":
                completed_note = ('completed_note' in object_data) ? object_data.completed_note : "";
                break;
            case "filechecker":
                completed_note = ("qc_score_result" in object_data && 'comment' in object_data.qc_score_result) ? object_data.qc_score_result.comment.replace(/'+/g,"'") : "";
                break;
        }
        return completed_note;
      }


	render()
	{
        let object_data = JSON.parse(this.props.modalData.activitylog_data.task_json).object_data;
        let module_id = this.props.modalData.module_name

        let comment = this.getTaskCompleteNote(module_id, object_data)


        console.log("object_data", comment)

        //console.log("ok3", JSON.parse(this.props.modalData.activitylog_data.task_json).object_data.qc_score_result.comment);
        
        return (
            <div>
                <MRModalContainer>
                    <MRModalHeader>
                        <MRModalHeaderText>Compliance Activity Completed</MRModalHeaderText>
                        <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                        <div style={{clear: "both"}}></div>
                    </MRModalHeader>
                    <MRModalBody>
                        <MRModalBodyInner>
                            <MRModalLabelTitle>Module:</MRModalLabelTitle>
                            <MRModalLabel>{this.props.modalData.module_name}</MRModalLabel>
                            <MRModalLabelTitle style={{paddingLeft:"5px"}}>Activity Type:</MRModalLabelTitle>
                            <MRModalLabel>Compliance Activity Completed</MRModalLabel>
                            <MRModalLabelTitle style={{paddingLeft:"5px"}}>Client:</MRModalLabelTitle>
                            <MRModalLabel>{this.props.modalData.client_name}</MRModalLabel>
                            <MRModalLabelTitle style={{paddingLeft:"4px"}}>Date:</MRModalLabelTitle>
                            <MRModalLabel>{this.props.modalData.changed_date}</MRModalLabel>
                            <MRModalDivider/>
                            <Spacer/>
                            <Spacer/>
                            <MRModalLabelTitle>Comments:</MRModalLabelTitle>
                            <MRModalLabel> 
                               <Spacer/>
                               {comment}
                            </MRModalLabel>
                           
                        </MRModalBodyInner>
                        <CLButton onClick={this.closeModal}>Close</CLButton>
                    </MRModalBody>
                </MRModalContainer>
            </div>                  
		);
	}
}

export default ActivityCompletedModal;